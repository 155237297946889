import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApiWithSessionExpiration } from "./useApiWithSessionExpiration";
import { FaTrash, FaEdit, FaPlus } from "react-icons/fa";
import ClientTabs from "./ClientTabs";
import Integrations from "./Integrations";
import Popup from "./Popup";
import "./ClientEdit.css";

function ClientEdit({ accessToken, apiBaseUrl, handleSessionExpiration, userData }) {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [assignedEmployees, setAssignedEmployees] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [newEmployeeId, setNewEmployeeId] = useState("");
  const [newEmployeeType, setNewEmployeeType] = useState("PRIMARY");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  const fetchClientData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apiCall(`${apiBaseUrl}clients/${id}`, {
        method: "GET",
        accessToken: accessToken,
      });
      const clientData = response.data;
      const sortedAssignedEmployees = clientData.assignedEmployees
        ? clientData.assignedEmployees.sort((a, b) => {
            return a.employee.person.displayName.localeCompare(b.employee.person.displayName);
          })
        : [];
      const sortedContacts = clientData.contacts
        ? clientData.contacts.sort((a, b) => {
            return a.person.email.localeCompare(b.person.email);
          })
        : [];
      setName(clientData.name);
      setAssignedEmployees(sortedAssignedEmployees);
      setContacts(sortedContacts);
      setError(null);
    } catch (err) {
      console.error("Error fetching client data:", err);
      setError("Failed to fetch client data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, apiBaseUrl, accessToken, id]);

  const fetchActiveEmployees = useCallback(async () => {
    try {
      const response = await apiCall(`${apiBaseUrl}employees?isActive=true`, {
        method: "GET",
        accessToken: accessToken,
      });
      setActiveEmployees(response.data);
    } catch (err) {
      console.error("Error fetching active employees:", err);
    }
  }, [apiCall, apiBaseUrl, accessToken]);

  useEffect(() => {
    fetchClientData();
    fetchActiveEmployees();
  }, [fetchClientData, fetchActiveEmployees]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSave = async () => {
    try {
      await apiCall(`${apiBaseUrl}clients/${id}`, {
        method: "PUT",
        accessToken: accessToken,
        data: { name: name },
      });
      showToast("Client updated", "success");
    } catch (err) {
      console.error("Error updating client:", err);
      showToast("Failed to update client", "error");
    }
  };

  const handleEditEmployee = (employee) => {
    setEditingEmployee(employee);
    setShowEditDialog(true);
  };

  const handleUpdateEmployee = async () => {
    const updatedAssignedEmployees = assignedEmployees.map((emp) =>
      emp.employeeId === editingEmployee.employeeId ? { ...emp, type: editingEmployee.type } : emp
    );
    try {
      await apiCall(`${apiBaseUrl}clients/${id}`, {
        method: "PUT",
        accessToken: accessToken,
        data: { assignedEmployees: updatedAssignedEmployees },
      });
      setAssignedEmployees(updatedAssignedEmployees);
      setShowEditDialog(false);
      showToast("Employee updated", "success");
    } catch (err) {
      console.error("Error updating employee:", err);
      showToast("Failed to update employee", "error");
    }
  };

  const handleDeleteEmployee = (employeeId) => {
    const employee = assignedEmployees.find((emp) => emp.employeeId === employeeId);
    setEmployeeToDelete(employee);
    setShowConfirmation(true);
  };

  const handleConfirmDeleteEmployee = async () => {
    const updatedAssignedEmployees = assignedEmployees.filter(
      (emp) => emp.employeeId !== employeeToDelete?.employee.id
    );
    try {
      await apiCall(`${apiBaseUrl}clients/${id}`, {
        method: "PUT",
        accessToken: accessToken,
        data: { assignedEmployees: updatedAssignedEmployees },
      });
      setShowConfirmation(false);
      setAssignedEmployees(updatedAssignedEmployees);
      showToast("Employee removed", "success");
    } catch (err) {
      console.error("Error removing employee:", err);
      showToast("Failed to remove employee", "error");
    }
  };

  const handleCancelDeleteEmployee = () => {
    setShowConfirmation(false);
    setEmployeeToDelete(null);
  };

  const handleAddEmployee = async () => {
    const newAssignedEmployees = [...assignedEmployees, { employeeId: newEmployeeId, type: newEmployeeType }];
    try {
      const updatedClientResponse = await apiCall(`${apiBaseUrl}clients/${id}`, {
        method: "PUT",
        accessToken: accessToken,
        data: { assignedEmployees: newAssignedEmployees },
      });
      const updatedClient = updatedClientResponse.data;
      const updatedAssignedEmployees = updatedClient.assignedEmployees;
      setAssignedEmployees(updatedAssignedEmployees);
      setShowAddDialog(false);
      setNewEmployeeId("");
      setNewEmployeeType("PRIMARY");
      showToast("Employee added", "success");
    } catch (err) {
      console.error("Error adding employee:", err);
      showToast("Failed to add employee", "error");
    }
  };

  const showToast = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  };

  return (
    <div className="client-edit">
      <div className="client-tabs-container">
        <ClientTabs clientId={id} activeTab="edit" />
      </div>
      {isLoading ? (
        <div className="loading-container">
          <p>Loading client data...</p>
        </div>
      ) : error ? (
        <div className="error-container">
          <p>{error}</p>
        </div>
      ) : (
        <>
          <div className="client-edit-content">
            <div className="client-name-container">
              <h3>Name</h3>
              {userData?.roles.includes("ADMIN") && <input type="text" value={name} onChange={handleNameChange} />}
              {!userData?.roles.includes("ADMIN") && <p>{name}</p>}
              {userData?.roles.includes("ADMIN") && (
                <button onClick={handleSave} className="name-save">
                  Save
                </button>
              )}
            </div>

            <div className="assigned-container">
              <h3>Assigned Employees</h3>
              <div className="table-container">
                {userData?.roles.includes("ADMIN") && (
                  <button onClick={() => setShowAddDialog(true)} className="add-assigned-employee">
                    <FaPlus /> Add Employee
                  </button>
                )}
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      {userData?.roles.includes("ADMIN") && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {assignedEmployees.map((employee) => (
                      <tr key={employee.employeeId}>
                        <td>{employee.employee?.person.displayName}</td>
                        <td>{employee.type}</td>
                        {userData?.roles.includes("ADMIN") && (
                          <td>
                            <button onClick={() => handleEditEmployee(employee)} className="edit-button">
                              <FaEdit />
                            </button>
                            <button onClick={() => handleDeleteEmployee(employee.employeeId)} className="delete-button">
                              <FaTrash />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Integrations Section */}
            {userData?.roles.includes("ADMIN") && (
              <Integrations
                accessToken={accessToken}
                apiBaseUrl={apiBaseUrl}
                handleSessionExpiration={handleSessionExpiration}
                apiCall={apiCall}
              />
            )}

            {userData?.roles.includes("ADMIN") && (
              <>
                <h3>Contacts</h3>
                <ul>
                  {contacts.map((contact) => (
                    <li key={contact.id}>{contact.person.email}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </>
      )}

      {showEditDialog && (
        <Popup onClose={() => setShowEditDialog(false)} minHeight="220px">
          <div className="edit-assigned-employee-dialog">
            <h4>Edit Employee</h4>
            <select
              value={editingEmployee.type}
              onChange={(e) => setEditingEmployee({ ...editingEmployee, type: e.target.value })}
            >
              <option value="PRIMARY">PRIMARY</option>
              <option value="SECONDARY">SECONDARY</option>
            </select>
            <div className="edit-assigned-employee-button-group">
              <button onClick={handleUpdateEmployee}>OK</button>
              <button onClick={() => setShowEditDialog(false)}>Cancel</button>
            </div>
          </div>
        </Popup>
      )}

      {showConfirmation && (
        <Popup onClose={() => handleCancelDeleteEmployee()} minHeight="180px">
          <div className="delete-assigned-employee-confirmation-dialog">
            <p>Are you sure you want to delete {employeeToDelete.employee?.person.displayName}?</p>
            <div className="edit-assigned-employee-button-group">
              <button onClick={handleConfirmDeleteEmployee}>Yes</button>
              <button onClick={handleCancelDeleteEmployee}>No</button>
            </div>
          </div>
        </Popup>
      )}

      {showAddDialog && (
        <Popup onClose={() => setShowAddDialog(false)} minHeight="280px">
          <div className="add-assigned-employee-dialog">
            <h4>Add Employee</h4>
            <select value={newEmployeeId} onChange={(e) => setNewEmployeeId(e.target.value)}>
              <option value="">Select an employee</option>
              {activeEmployees
                .sort((a, b) => a.person.displayName.toLowerCase().localeCompare(b.person.displayName.toLowerCase()))
                .map((emp) => (
                  <option key={emp.id} value={emp.id}>
                    {emp.person.displayName}
                  </option>
                ))}
            </select>
            <select value={newEmployeeType} onChange={(e) => setNewEmployeeType(e.target.value)}>
              <option value="PRIMARY">PRIMARY</option>
              <option value="SECONDARY">SECONDARY</option>
            </select>
            <div className="edit-assigned-employee-button-group">
              <button onClick={handleAddEmployee}>Add</button>
              <button onClick={() => setShowAddDialog(false)}>Cancel</button>
            </div>
          </div>
        </Popup>
      )}

      {toast && <div className={`toast ${toast.type}`}>{toast.message}</div>}
    </div>
  );
}

export default ClientEdit;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useApiWithSessionExpiration } from './useApiWithSessionExpiration';
import { FaCheck, FaTrash, FaPlus, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import './Employees.css';

function Employees({ accessToken, apiBaseUrl, handleSessionExpiration, userData }) {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [newEmployeeEmail, setNewEmployeeEmail] = useState('');
  const [toast, setToast] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  const fetchEmployees = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apiCall(`${apiBaseUrl}employees?isActive=true`, {
        method: 'GET',
        accessToken: accessToken
      });
      setEmployees(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching employees:', err);
      setError('Failed to fetch employees. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, apiBaseUrl, accessToken]);

  useEffect(() => {
    if (accessToken && apiBaseUrl) {
      fetchEmployees();
    }
  }, [accessToken, apiBaseUrl, fetchEmployees]);

  const sortedEmployees = useMemo(() => {
    let sortableEmployees = [...employees];
    if (sortConfig.key) {
      sortableEmployees.sort((a, b) => {
        let aValue = sortConfig.key === 'name' ? a.person.displayName : a.person.email;
        let bValue = sortConfig.key === 'name' ? b.person.displayName : b.person.email;
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableEmployees;
  }, [employees, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  const handleDeleteClick = (employee) => {
    setEmployeeToDelete(employee);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await apiCall(`${apiBaseUrl}employees/${employeeToDelete.id}`, {
        method: 'DELETE',
        accessToken: accessToken
      });
      setShowConfirmation(false);
      setEmployeeToDelete(null);
      fetchEmployees();
      showToast('Employee successfully deleted', 'success');
    } catch (err) {
      console.error('Error deleting employee:', err);
      showToast('Failed to delete employee', 'error');
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setEmployeeToDelete(null);
  };

  const handleAddEmployee = async () => {
    try {
      await apiCall(`${apiBaseUrl}employees`, {
        method: 'POST',
        accessToken: accessToken,
        data: { email: newEmployeeEmail }
      });
      setShowAddDialog(false);
      setNewEmployeeEmail('');
      fetchEmployees();
      showToast('Employee successfully added', 'success');
    } catch (err) {
      console.error('Error adding employee:', err);
      showToast('Failed to add employee', 'error');
    }
  };

  const showToast = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  };

  if (isLoading) {
    return <div className="employees-loading">Loading employees...</div>;
  }

  if (error) {
    return <div className="employees-error">{error}</div>;
  }

  return (
    <div className="employees-container">
      <h2>Employees</h2>
      <div className="table-container">
        <button onClick={() => setShowAddDialog(true)} className="add-employee-button">
          <FaPlus /> Add Employee
        </button>
        <table className="employees-table">
          <thead>
            <tr>
              <th className="name-column sortable" onClick={() => requestSort('name')}>
                Name {getSortIcon('name')}
              </th>
              <th className="email-column sortable" onClick={() => requestSort('email')}>
                Email {getSortIcon('email')}
              </th>
              <th className="assignee-column">Default Assignee</th>
              <th className="action-column">Action</th>
            </tr>
          </thead>
          <tbody>
            {sortedEmployees.map((employee) => (
              <tr key={employee.id}>
                <td className="name-column">{employee.person.displayName}</td>
                <td className="email-column">{employee.person.email}</td>
                <td className="assignee-column center-align">
                  {employee.isDefaultTaskAssignee && <FaCheck className="check-icon" />}
                </td>
                <td className="action-column center-align">
                  <button onClick={() => handleDeleteClick(employee)} className="delete-button">
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showConfirmation && (
        <div className="confirmation-dialog">
          <p>Are you sure you want to delete {employeeToDelete?.person.displayName}?</p>
          <button onClick={handleConfirmDelete}>Yes</button>
          <button onClick={handleCancelDelete}>No</button>
        </div>
      )}

      {showAddDialog && (
        <div className="add-employee-dialog">
          <h3>Add New Employee</h3>
          <input
            type="email"
            value={newEmployeeEmail}
            onChange={(e) => setNewEmployeeEmail(e.target.value)}
            placeholder="Enter email address"
          />
          <button onClick={handleAddEmployee}>Add</button>
          <button onClick={() => setShowAddDialog(false)}>Cancel</button>
        </div>
      )}

      {toast && (
        <div className={`toast ${toast.type}`}>
          {toast.message}
        </div>
      )}
    </div>
  );
}

export default Employees;
import React, { useState, useEffect, useCallback, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { useChat } from "./ChatContext";
import Profile from "./Profile";
import Chat from "./Chat";
import Employees from "./Employees";
import Clients from "./Clients";
import ClientEdit from "./ClientEdit";
import ClientReviews from "./ClientReviews";
import CompletedReviews from "./CompletedReviews";
import TaskDetails from "./TaskDetails";
import TaskBoard from "./TaskBoard";
import Dashboard from "./Dashboard";
import OAuthCallback from "./OAuthCallback";
import { useApiWithSessionExpiration } from "./useApiWithSessionExpiration";
import { FaBars, FaUserCircle, FaCaretDown } from "react-icons/fa";
import logo from "./IR+Logo+mountain+RED.png";
import cloud from "./Cloud-Database-Table.png";
import microsoftLogo from "./microsoft-logo.png";
import "./App.css";

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState(null);
  const [apiBaseUrl, setApiBaseUrl] = useState("https://axion.ironroadresearch.com/v1.0/");
  const [userPrincipalName, setUserPrincipalName] = useState(null);
  const [menuOpen, setMenuOpen] = useState(window.innerWidth > 768);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const userDropdownRef = useRef(null);

  const handleSessionExpiration = useCallback(() => {
    setAccessToken(null);
    setUserPrincipalName(null);
    instance.clearCache();
    const currentAccounts = instance.getAllAccounts();
    if (currentAccounts.length > 0) {
      instance.logout();
    }
  }, [instance]);

  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  const acquireAccessToken = useCallback(() => {
    if (accounts.length > 0) {
      const account = accounts[0];
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response) => {
          setUserPrincipalName(account.username);
          setAccessToken(response.accessToken);
        })
        .catch((e) => {
          console.error("Silent token acquisition failed. Using interactive method", e);
          instance
            .acquireTokenPopup({
              ...loginRequest,
              account: account,
            })
            .then((response) => {
              setAccessToken(response.accessToken);
            })
            .catch((err) => {
              console.error(err);
            });
        });
    }
  }, [instance, accounts]);

  useEffect(() => {
    if (isAuthenticated && accounts.length > 0 && !accessToken) {
      acquireAccessToken();
    }
  }, [isAuthenticated, accounts, accessToken, acquireAccessToken]);

  const fetchUserData = useCallback(
    async (token) => {
      try {
        const response = await apiCall(`${apiBaseUrl}getAuthUserData`, {
          method: "GET",
          accessToken: token,
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    [apiCall, apiBaseUrl]
  );

  useEffect(() => {
    if (isAuthenticated && accessToken) {
      fetchUserData(accessToken);
    }
  }, [isAuthenticated, accessToken, fetchUserData]);

  const handleLogin = useCallback(() => {
    console.log(`Logging in, authority is ${process.env.REACT_APP_AUTHORITY}`);
    instance
      .loginPopup(loginRequest)
      .then(() => {
        acquireAccessToken();
      })
      .catch((e) => {
        console.error(e);
      });
  }, [instance, acquireAccessToken]);

  const { clearChatHistories } = useChat();

  const handleLogout = () => {
    instance
      .logoutPopup()
      .then(() => {
        setAccessToken(null);
        setUserPrincipalName(null);
        setUserDropdownOpen(false);
        setUserData(null);
        clearChatHistories();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleToggle = () => {
    setApiBaseUrl(
      apiBaseUrl === "http://127.0.0.1:7074/v1.0/"
        ? "https://axion.ironroadresearch.com/v1.0/"
        : "http://127.0.0.1:7074/v1.0/"
    );
  };

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleResize = () => {
      setMenuOpen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (userDropdownRef.current && !userDropdownRef.current.contains(event.target)) {
        setUserDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <button className="menu-toggle" onClick={toggleMenu}>
            <FaBars />
          </button>
          <div className="App-title">
            <img src={logo} className="App-logo" alt="logo" />
            <h1>Marketing Reviews</h1>
          </div>
          <div className="header-spacer">
            {isAuthenticated && (
              <div className={`user-dropdown ${userDropdownOpen ? "user-dropdown-open" : ""}`} ref={userDropdownRef}>
                <button onClick={toggleUserDropdown} className="user-dropdown-toggle">
                  <FaUserCircle />
                  <span>{userData ? userData.displayName : userPrincipalName}</span>
                  <FaCaretDown />
                </button>
                {userDropdownOpen && (
                  <div className="user-dropdown-menu">
                    <Profile accessToken={accessToken} apiCall={apiCall} apiBaseUrl={apiBaseUrl} />
                    <button onClick={handleLogout} className="logout-button">
                      Logout
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </header>
        {isAuthenticated ? (
          <div className="content-wrapper">
            <nav className={`side-menu ${menuOpen ? "open" : ""}`}>
              <ul>
                {userData?.permissions.includes("askQuestion") && (
                  <li>
                    <Link to="/chat" onClick={toggleMenu}>
                      Chat
                    </Link>
                  </li>
                )}
                {userData?.roles.includes("ADMIN") && (
                  <li>
                    <Link to="/employees" onClick={toggleMenu}>
                      Employees
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/clients" onClick={toggleMenu}>
                    Clients
                  </Link>
                </li>
                <li>
                  <Link to="/board" onClick={toggleMenu}>
                    Board
                  </Link>
                </li>
                {userData?.roles.includes("ADMIN") && (
                  <li>
                    <Link to="/dashboard" onClick={toggleMenu}>
                      Dashboard
                    </Link>
                  </li>
                )}
                {userData?.roles.includes("DEVELOPER") && (
                  <li className="environment-toggle">
                    <span>Environment</span>
                    <div className="toggle-container">
                      <label className="toggle-label">
                        <input
                          type="checkbox"
                          checked={apiBaseUrl === "https://axion.ironroadresearch.com/v1.0/"}
                          onChange={handleToggle}
                          className="toggle-input"
                        />
                        <span className="toggle-switch"></span>
                        <span>
                          {apiBaseUrl === "https://axion.ironroadresearch.com/v1.0/" ? "Production" : "Local"}
                        </span>
                      </label>
                    </div>
                  </li>
                )}
              </ul>
            </nav>
            <main className="main-content">
              <Routes>
                <Route
                  path="/"
                  element={
                    <TaskBoard
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      userData={userData}
                      key={`board-${apiBaseUrl}`}
                    />
                  }
                />
                <Route
                  path="/chat"
                  element={
                    <Chat
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      userData={userData}
                      key={`chat-${apiBaseUrl}`}
                    />
                  }
                />
                <Route
                  path="/employees"
                  element={
                    <Employees
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      userData={userData}
                      key={`employees-${apiBaseUrl}`}
                    />
                  }
                />
                <Route
                  path="/clients"
                  element={
                    <Clients
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      userData={userData}
                      key={`clients-${apiBaseUrl}`}
                    />
                  }
                />
                <Route
                  path="/board"
                  element={
                    <TaskBoard
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      userData={userData}
                      key={`board-${apiBaseUrl}`}
                    />
                  }
                />
                <Route
                  path="/clients/:id/edit"
                  element={
                    <ClientEdit
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      userData={userData}
                      key={`client-edit-${apiBaseUrl}`}
                    />
                  }
                />
                <Route
                  path="/clients/:id/reviews"
                  element={
                    <ClientReviews
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      userData={userData}
                      key={`client-reviews-${apiBaseUrl}`}
                    />
                  }
                />
                <Route
                  path="/clients/:id/completedreviews"
                  element={
                    <CompletedReviews
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      userData={userData}
                      key={`completed-reviews-${apiBaseUrl}`}
                    />
                  }
                />
                <Route
                  path="/task/:taskId"
                  element={
                    <TaskDetails
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      isPopup={false}
                      showDelete={userData?.roles.includes("ADMIN")}
                      key={`task-details-${apiBaseUrl}`}
                    />
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <Dashboard
                      accessToken={accessToken}
                      apiBaseUrl={apiBaseUrl}
                      handleSessionExpiration={handleSessionExpiration}
                      userData={userData}
                      onTilesUpdate={(updatedTiles) => {
                        // Update userData with new tile positions
                        setUserData((prevState) => {
                          return {
                            ...prevState,
                            dashboardTiles: updatedTiles,
                          };
                        });
                      }}
                    />
                  }
                />
                <Route
                  path="/oauth/callback"
                  element={
                    <OAuthCallback accessToken={accessToken} handleSessionExpiration={handleSessionExpiration} />
                  }
                />
              </Routes>
            </main>
          </div>
        ) : (
          <div className="SignedOut-screen">
            <img src={cloud} className="SignedOut-logo" alt="signed out logo" />
            <button className="SignedOut-login-button" onClick={handleLogin}>
              <img src={microsoftLogo} alt="Microsoft logo" className="Microsoft-logo" />
              <span>Login with Microsoft</span>
            </button>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;

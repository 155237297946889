import { useCallback } from 'react';
import axios from 'axios';

export const useApiWithSessionExpiration = (handleSessionExpiration) => {
  return useCallback(async (url, options = {}) => {
    try {
      const { method = 'GET', headers = {}, data, accessToken, ...restOptions } = options;

      if (!headers['Authorization'] && accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }

      const axiosConfig = {
        ...restOptions,
        method,
        url,
        headers,
        data, // Use 'data' for the request body in axios
      };

      const response = await axios(axiosConfig);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleSessionExpiration();
        throw new Error('Session expired');
      }
      // Handle other errors
      console.error('API call failed:', error);
      throw error;
    }
  }, [handleSessionExpiration]);
};
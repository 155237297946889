import React, { useState, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';

const ClientFilter = ({ tasks, onFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const uniqueClients = [...new Set(tasks.map(task => task.clientName))];
    setClients(uniqueClients);
  }, [tasks]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleClientChange = (client) => {
    setSelectedClients(prev => {
      const newSelection = prev.includes(client)
        ? prev.filter(e => e !== client)
        : [...prev, client];
      
      onFilterChange(newSelection);
      return newSelection;
    });
  };

  return (
    <div className="client-filter">
      <button onClick={handleToggle} className="filter-button">
        <span className="filter-button-text">
            {selectedClients.length > 0 ? `${selectedClients.length} Client${selectedClients.length > 1 ? 's' : ''} Selected` : 'No Client Filter'}
        </span>
        <span className="filter-button-icon">
            <FaChevronDown />
        </span>
      </button>
      {isOpen && (
        <div className="client-list">
          {clients.map(client => (
            <label key={client} className="client-item">
              <input
                type="checkbox"
                checked={selectedClients.includes(client)}
                onChange={() => handleClientChange(client)}
              />
              <span>{client}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default ClientFilter;
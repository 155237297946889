import React, { useEffect, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import './Popup.css';

const Popup = ({ children, onClose, minHeight }) => {
  const popupRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="popup-overlay">
      <div className="popup-content" ref={popupRef} style={{ minHeight: minHeight || '700px' }}>
        <button className="popup-close" onClick={onClose}>
          <FaTimes />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
import React, { useState, useEffect, useCallback, useRef, useLayoutEffect, useMemo } from 'react';
import { useApiWithSessionExpiration } from './useApiWithSessionExpiration';
import './Dashboard.css';
import DonutChart from './DonutChart';
import { debounce } from 'lodash';
import BarChart from './BarChart';
import LineChart from './LineChart';
import DashboardTable from './DashboardTable';

const COLUMN_WIDTH = 100;
const DEFAULT_GAP = 16;

const getCSSVariable = (element, variableName) => {
  const value = getComputedStyle(element).getPropertyValue(variableName);
  return parseFloat(value); // Convert the value to a number
};

const checkCollision = (tile1, tile2) => {
    return !(
      tile1.gridPosition.x + tile1.gridPosition.w <= tile2.gridPosition.x ||
      tile2.gridPosition.x + tile2.gridPosition.w <= tile1.gridPosition.x ||
      tile1.gridPosition.y + tile1.gridPosition.h <= tile2.gridPosition.y ||
      tile2.gridPosition.y + tile2.gridPosition.h <= tile1.gridPosition.y
    );
  };

  const findValidPosition = (newPosition, tiles, currentTileId, gridColumns) => {
    // Create a temporary tile object with gridPosition structure
    const testTile = { gridPosition: newPosition };
    
    // First check if position is within grid bounds
    testTile.gridPosition.x = Math.max(0, testTile.gridPosition.x);
    testTile.gridPosition.y = Math.max(0, testTile.gridPosition.y);
    if (testTile.gridPosition.x + testTile.gridPosition.w > gridColumns) {
      testTile.gridPosition.x = gridColumns - testTile.gridPosition.w;
    }
  
    // Check for collisions with other tiles
    const hasCollision = tiles.some(tile => 
      checkCollision(testTile, tile)
    );
  
    if (!hasCollision) return testTile.gridPosition;
  
    // If there's a collision, try to find the nearest valid position
    // First try moving right
    let testPos = { ...testTile.gridPosition };
    while (testPos.x + testPos.w <= gridColumns) {
      const testTilePos = { gridPosition: testPos };
      if (!tiles.some(tile => checkCollision(testTilePos, tile))) {
        return testPos;
      }
      testPos.x++;
    }
  
    // If no valid position found, try the next row
    testPos = { ...testTile.gridPosition, x: 0, y: testTile.gridPosition.y + 1 };
    while (testPos.y < 100) { // Arbitrary limit to prevent infinite loop
      while (testPos.x + testPos.w <= gridColumns) {
        const testTilePos = { gridPosition: testPos };
        if (!tiles.some(tile => checkCollision(testTilePos, tile))) {
          return testPos;
        }
        testPos.x++;
      }
      testPos.y++;
      testPos.x = 0;
    }
  
    return testTile.gridPosition; // Fallback to original position if no valid position found
  };

const ResizeHandle = ({ position, onResizeStart }) => {
  return (
    <div
      className={`resize-handle resize-handle-${position}`}
      onMouseDown={(e) => {
        e.stopPropagation();
        onResizeStart(position, e);
      }}
    />
  );
};

const DashboardTile = ({ 
  id, 
  gridPosition,
  gridColumns,
  onPositionChange,
  onSizeChange,
  children,
  isEditing,
  title,
  settings 
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [ghostPosition, setGhostPosition] = useState({ x: 0, y: 0 });
  const [startSize, setStartSize] = useState({ w: gridPosition.w, h: gridPosition.h });
  const [resizeDirection, setResizeDirection] = useState(null);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentSize, setCurrentSize] = useState({ w: gridPosition.w, h: gridPosition.h });
  const [ghostSize, setGhostSize] = useState({ w: gridPosition.w, h: gridPosition.h });
  const [columnWidth, setColumnWidth] = useState(COLUMN_WIDTH);
  const [gap, setGap] = useState(DEFAULT_GAP);
  const tileRef = useRef(null);
  const gridRef = useRef(null);

  // Set gridRef.current using useLayoutEffect
  useLayoutEffect(() => {
    if (tileRef.current) {
      gridRef.current = tileRef.current.closest('.dashboard-grid');
    }
  }, []);

  // Use useEffect to set up ResizeObserver when gridRef.current is available
  useEffect(() => {
    if (gridRef.current) {
      const updateCSSVariables = () => {
        const columnWidthValue = getCSSVariable(gridRef.current, '--column-width');
        const gapValue = getCSSVariable(gridRef.current, '--grid-gap');
        setColumnWidth(columnWidthValue !== null ? columnWidthValue : COLUMN_WIDTH);
        setGap(gapValue !== null ? gapValue : DEFAULT_GAP);
      };

      // Initial update
      updateCSSVariables();

      // Set up ResizeObserver
      const resizeObserver = new ResizeObserver(() => {
        updateCSSVariables();
      });
      resizeObserver.observe(gridRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const handleMouseDown = (e) => {
    if (!isEditing || !tileRef.current || !gridRef.current) return;
    
    e.preventDefault();
    
    const tileRect = tileRef.current.getBoundingClientRect();
    
    // Calculate offset from mouse to tile's top-left corner
    const offsetX = e.clientX - tileRect.left;
    const offsetY = e.clientY - tileRect.top;

    setDragOffset({ x: offsetX, y: offsetY });
    setMousePosition({ x: e.clientX, y: e.clientY });
    setGhostPosition({ x: gridPosition.x, y: gridPosition.y });
    setIsDragging(true);
  };

  const calculateGridPosition = useCallback((clientX, clientY) => {
    if (!gridRef.current) return { x: 0, y: 0 };
  
    const gridRect = gridRef.current.getBoundingClientRect();
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;
  
    const tileX = clientX - dragOffset.x;
    const tileY = clientY - dragOffset.y;
  
    const relativeX = tileX - gridRect.left + scrollX;
    const relativeY = tileY - gridRect.top + scrollY;
  
    const gridX = Math.max(0, Math.round(relativeX / (columnWidth + gap)));
    const gridY = Math.max(0, Math.round(relativeY / (columnWidth + gap)));
  
    return { x: gridX, y: gridY };
  }, [dragOffset, columnWidth, gap]);

  const handleMouseMove = useCallback((e) => {
    if (!isDragging) return;

    // Update the actual tile position to follow the mouse
    setMousePosition({ x: e.clientX, y: e.clientY });

    // Calculate and update the ghost position on the grid
    const newGridPosition = calculateGridPosition(e.clientX, e.clientY);
    setGhostPosition(newGridPosition);
  }, [isDragging, calculateGridPosition]);
    
  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);

      // Calculate final position and call the update handler
      const finalPosition = {
        ...gridPosition,
        x: ghostPosition.x,
        y: ghostPosition.y
      };

      onPositionChange(id, finalPosition);

      // Reset states
      setMousePosition({ x: 0, y: 0 });
      setGhostPosition({ x: 0, y: 0 });
      setDragOffset({ x: 0, y: 0 });
    }
  }, [isDragging, ghostPosition, gridPosition, id, onPositionChange]);

  const handleResizeStart = (direction, e) => {
    if (!isEditing) return;
  
    setIsResizing(true);
    setResizeDirection(direction);
    setStartPos({ x: e.clientX, y: e.clientY });
    setStartSize({ w: gridPosition.w, h: gridPosition.h });
    setCurrentSize({ w: gridPosition.w, h: gridPosition.h });
    setGhostSize({ w: gridPosition.w, h: gridPosition.h });
    e.preventDefault();
  };
  
  const handleResizeMove = useCallback((e) => {
    if (!isResizing) return;
  
    // Valid sizes based on settings
    const validSizes = settings.validTileSizes || [2, 4, 6];

    // Calculate exact mouse movement in grid units
    const deltaX = (e.clientX - startPos.x) / (columnWidth + gap);
    const deltaY = (e.clientY - startPos.y) / (columnWidth + gap);
  
    // Update current size to follow mouse exactly (no rounding)
    let newW = startSize.w;
    let newH = startSize.h;
  
    if (resizeDirection.includes('e')) {
      newW = Math.max(1, startSize.w + deltaX);
    }
    if (resizeDirection.includes('s')) {
      newH = Math.max(1, startSize.h + deltaY);
    }
  
    setCurrentSize({ w: newW, h: newH });
  
    // Calculate snapped size for ghost (rounded to valid sizes)
    const roundedW = Math.max(1, Math.round(newW));
    const roundedH = Math.max(1, Math.round(newH));
  
    const snappedW = validSizes.reduce((prev, curr) => 
      Math.abs(curr - roundedW) < Math.abs(prev - roundedW) ? curr : prev
    );
    const snappedH = validSizes.reduce((prev, curr) => 
      Math.abs(curr - roundedH) < Math.abs(prev - roundedH) ? curr : prev
    );
  
    // Update ghost size

    setGhostSize({ w: snappedW, h: snappedH });
  }, [isResizing, startPos, startSize, resizeDirection, columnWidth, gap, settings]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    if (isResizing) {
      const handleResizeEnd = () => {
        if (isResizing) {
          setIsResizing(false);
          setResizeDirection(null);
    
          // Apply the ghost size
          onSizeChange(id, { 
            ...gridPosition, 
            w: ghostSize.w, 
            h: ghostSize.h 
          });
      
          // Reset states
          setCurrentSize({ w: gridPosition.w, h: gridPosition.h });
          setGhostSize({ w: gridPosition.w, h: gridPosition.h });
        }
      };
  
      window.addEventListener('mousemove', handleResizeMove);
      window.addEventListener('mouseup', handleResizeEnd);
      return () => {
        window.removeEventListener('mousemove', handleResizeMove);
        window.removeEventListener('mouseup', handleResizeEnd);
      };
    }
  }, [isResizing, handleResizeMove, ghostSize, gridPosition, id, onSizeChange]);

  const getDraggedTileStyle = () => {
    if (isDragging) {
      return {
        position: 'fixed',
        left: `${mousePosition.x - dragOffset.x}px`,
        top: `${mousePosition.y - dragOffset.y}px`,
        width: `${gridPosition.w * columnWidth + (gridPosition.w - 1) * gap}px`,
        height: `${gridPosition.h * columnWidth + (gridPosition.h - 1) * gap}px`,
        zIndex: 1000,
        cursor: 'move',
        pointerEvents: 'none',
        opacity: 0.8,
        transform: 'scale(1.05)',
      };
    }
    return {};
  };
  
  const getGhostStyle = () => {
    const baseStyle = {
      opacity: 0.4,
      backgroundColor: '#ccc',
      border: '2px dashed #666',
      zIndex: 1,
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      position: 'relative',
      display: 'block'
    };
  
    if (isDragging) {
      return {
        ...baseStyle,
        gridColumnStart: `${ghostPosition.x + 1 + gridPosition.w <= gridColumns + 1 ? ghostPosition.x + 1 : gridColumns + 1 - ghostSize.w}`,
        gridColumnEnd: `span ${gridPosition.w}`,
        gridRowStart: ghostPosition.y + 1,
        gridRowEnd: `span ${gridPosition.h}`,
        minHeight: `${gridPosition.h * columnWidth + (gridPosition.h - 1) * gap}px`,
      };
    }
  
    if (isResizing) {
      return {
        ...baseStyle,
        gridColumnStart: gridPosition.x + 1,
        gridColumnEnd: `span ${ghostSize.w}`,
        gridRowStart: gridPosition.y + 1,
        gridRowEnd: `span ${ghostSize.h}`,
        minHeight: `${ghostSize.h * columnWidth + (ghostSize.h - 1) * gap}px`,
      };
    }
  
    return baseStyle;
  };

  const getStaticStyle = () => ({
    gridColumnStart: gridPosition.x + 1,
    gridColumnEnd: `span ${gridPosition.w}`,
    gridRowStart: gridPosition.y + 1,
    gridRowEnd: `span ${gridPosition.h}`,
    cursor: isEditing ? 'move' : 'default',
    zIndex: isResizing ? 1000 : 1,
  });

  const getResizingStyle = () => ({
    ...getStaticStyle(),
    width: `${currentSize.w * columnWidth + (currentSize.w - 1) * gap}px`,
    height: `${currentSize.h * columnWidth + (currentSize.h - 1) * gap}px`,
    transition: 'none',
    opacity: 0.8,
  });

  return (
    <>
    <div 
      ref={tileRef}
      className={`dashboard-tile ${isResizing ? 'resizing' : ''}`}
      style={
        isDragging ? getDraggedTileStyle() : 
        isResizing ? getResizingStyle() :
        getStaticStyle()
      }
      onMouseDown={handleMouseDown}
    >
        {title && <div className="dashboard-tile-header">{title}</div>}
        <div className="dashboard-tile-content">
          {children}
        </div>
        {isEditing && !isDragging && (
          <>
            <ResizeHandle position="se" onResizeStart={handleResizeStart} />
            <ResizeHandle position="e" onResizeStart={handleResizeStart} />
            <ResizeHandle position="s" onResizeStart={handleResizeStart} />
          </>
        )}
      </div>
      {(isDragging || isResizing) && (
        <div 
          className="dashboard-tile-ghost"
          style={getGhostStyle()}
        />
      )}
    </>
  );
};

const Dashboard = ({ 
  accessToken, 
  apiBaseUrl, 
  handleSessionExpiration,
  userData,
  onTilesUpdate 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [didAnimate, setDidAnimate] = useState(false);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState({});
  const [currentTiles, setCurrentTiles] = useState(userData?.dashboardTiles ?? []);
  const [gridRows, setGridRows] = useState(Math.max(...(userData?.dashboardTileSettings?.validTileSizes ?? [6])));
  
  const gridColumns = 12;
  const validTileSizes = userData?.dashboardTileSettings?.validTileSizes ?? [2, 4, 6];
  const maxTileHeight = Math.max(...validTileSizes);
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  // Create a memoized debounced function for updating tiles
  const debouncedUpdateTiles = useMemo(
    () => debounce(async (updatedTiles) => {
      try {
        await apiCall(`${apiBaseUrl}dashboardtiles`, {
          method: 'PUT',
          accessToken,
          data: {
            tiles: updatedTiles.map(({ id, gridPosition }) => ({
              id,
              gridPosition
            }))
          }
        });
        onTilesUpdate(updatedTiles);
      } catch (error) {
        console.error('Failed to update tiles:', error);
        setCurrentTiles(userData?.dashboardTiles ?? []);
      }
    }, 1000),
    [apiCall, apiBaseUrl, accessToken, onTilesUpdate, userData?.dashboardTiles]
  );

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedUpdateTiles.cancel();
    };
  }, [debouncedUpdateTiles]);

  const fetchDashboardData = useCallback(async () => {
    try {
      const response = await apiCall(`${apiBaseUrl}dashboarddata`, {
        method: 'GET',
        accessToken: accessToken
      });
      setDashboardData(response.data);
      setCurrentTiles(userData?.dashboardTiles ?? []);
      setError(null);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to fetch dashboard data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, apiBaseUrl, accessToken, userData]);

  useEffect(() => {
    if (accessToken && apiBaseUrl) {
      fetchDashboardData();
    }
  }, [accessToken, apiBaseUrl, fetchDashboardData]);

  const calculateGridRows = useCallback(() => {
    if (currentTiles.length === 0) return maxTileHeight;
  
    const maxBottomEdge = currentTiles.reduce((max, tile) => {
      const tileBottom = tile.gridPosition.y + tile.gridPosition.h;
      return Math.max(max, tileBottom);
    }, 1);
  
    return isEditing ? maxBottomEdge + maxTileHeight : maxBottomEdge;
  }, [currentTiles, maxTileHeight, isEditing]);

  useEffect(() => {
    const rows = calculateGridRows();
    setGridRows(rows);
  }, [calculateGridRows]);

  useEffect(() => {
    if (!didAnimate && isEditing) {
      setDidAnimate(true);
    }
  }, [didAnimate, isEditing]);

  // Combined handler for both position and size changes
  const handleTileUpdate = useCallback((tileId, newPosition) => {
    // Find valid position considering collisions
    const validPosition = findValidPosition(
      newPosition,
      currentTiles.filter(t => t.id !== tileId),
      tileId,
      gridColumns
    );

    const updatedTiles = currentTiles.map(tile => 
      tile.id === tileId 
        ? { ...tile, gridPosition: validPosition }
        : tile
    );

    // Update local state immediately for responsive UI
    setCurrentTiles(updatedTiles);

    // Trigger debounced server update
    debouncedUpdateTiles(updatedTiles);
  }, [currentTiles, gridColumns, debouncedUpdateTiles]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h2>Dashboard</h2>
        <button 
          className="edit-toggle"
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ? 'Save Layout' : 'Edit Layout'}
        </button>
      </div>
      {isLoading ? (
        <div className="dashboard-loading">Loading dashboard...</div>
      ) : error ? (
        <div className="dashboard-error">{error}</div>
      ) : (
        <div 
          className="dashboard-grid"
          style={{
            gridTemplateColumns: `repeat(${gridColumns}, minmax(0, var(--column-width, ${COLUMN_WIDTH}px)))`,
            gap: `var(--grid-gap, ${DEFAULT_GAP}px)`,
            position: 'relative',
            minHeight: `calc(${gridRows} * var(--column-width, ${COLUMN_WIDTH}px) + (${gridRows} - 1) * var(--grid-gap, ${DEFAULT_GAP}px))`,
          }}
        >
          {currentTiles.map(tile => (
            <DashboardTile
              key={tile.id}
              id={tile.id}
              gridPosition={tile.gridPosition}
              gridColumns={gridColumns}
              onPositionChange={handleTileUpdate}
              onSizeChange={handleTileUpdate}
              isEditing={isEditing}
              title={tile.title}
              settings={userData?.dashboardTileSettings ?? {}}
            >
              {!isLoading && !error && dashboardData[tile.id] && tile?.display?.type === 'DonutChart' && (
                <DonutChart 
                  data={dashboardData[tile.id]}
                  userDashboardTileSettings={userData?.dashboardTileSettings}
                  tileSettings={tile.display} 
                  animate = {!didAnimate}
                />
              )}
              {!isLoading && !error && dashboardData[tile.id] && tile?.display?.type === 'BarChart' && (
                <BarChart 
                  data={dashboardData[tile.id]}
                  userDashboardTileSettings={userData?.dashboardTileSettings}
                  tileSettings={tile.display} 
                  animate = {false}
                />
              )}
              {!isLoading && !error && dashboardData[tile.id] && tile?.display?.type === 'LineChart' && (
                <LineChart 
                  data={dashboardData[tile.id]}
                  userDashboardTileSettings={userData?.dashboardTileSettings}
                  tileSettings={tile.display} 
                  animate={!didAnimate}
                />
              )}
              {!isLoading && !error && dashboardData[tile.id] && tile?.display?.type === 'DashboardTable' && (
                <DashboardTable 
                  data={dashboardData[tile.id]}
                  userDashboardTileSettings={userData?.dashboardTileSettings}
                  tileSettings={tile.display} 
                  animate={!didAnimate}
                />
              )}
              {!("type" in tile?.display) && (<div>{JSON.stringify(dashboardData[tile.id])}</div>)}
            </DashboardTile>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
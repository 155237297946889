import React from 'react';
import { AlertCircle, AlertTriangle, Circle, ChevronDown, Paperclip, FileText, Calendar } from 'lucide-react';
import './TaskCard.css';

const priorityConfig = {
    URGENT: { icon: AlertCircle, color: 'text-red-500', bgColor: 'bg-red-100' },
    HIGH: { icon: AlertTriangle, color: 'text-orange-500', bgColor: 'bg-orange-100' },
    MEDIUM: { icon: Circle, color: 'text-yellow-500', bgColor: 'bg-yellow-100' },
    LOW: { icon: ChevronDown, color: 'text-green-500', bgColor: 'bg-green-100' },
};

const TaskCard = ({ client, title, priority, attachments, pages = 0, dueDate, assignee, state, completedDate }) => {
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const mm = String(d.getMonth() + 1).padStart(2, '0');
    const dd = String(d.getDate()).padStart(2, '0');
    const hh = d.getHours() % 12 || 12;
    const min = String(d.getMinutes()).padStart(2, '0');
    const ampm = d.getHours() >= 12 ? 'pm' : 'am';
    return `${mm}/${dd} ${hh}:${min}${ampm}`;
  };

  const getInitials = (name) => {
    const names = name.split(' ');
    return `${names[0][0]}${names[names.length - 1][0]}`.toUpperCase();
  };

  const generateColorFromName = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = hash % 360;
    return `hsl(${h}, 60%, 35%)`; // Using HSL to ensure a dark shade
  };

  const getDueDateClass = () => {
    if (['OPEN', 'ASSIGNED', 'IN_PROGRESS'].includes(state) && completedDate === null) {
      const now = new Date();
      const due = new Date(dueDate);
      const oneDayFromNow = new Date(now.getTime() + 24 * 60 * 60 * 1000);

      if (due < now) {
        return 'due-date-overdue';
      } else if (due < oneDayFromNow) {
        return 'due-date-warning';
      }
    }
    return '';
  };

  const PriorityIcon = priorityConfig[priority].icon;
  const assigneeColor = generateColorFromName(assignee);
  const dueDateClass = getDueDateClass();

  return (
    <div className={`task-card ${priorityConfig[priority].bgColor}`}>
      <p className="client-name">{client}</p>
      <h3 className="task-title">{truncateText(title, 150)}</h3>
      <div className="card-footer">
        <div className="card-meta">
          <span className={`priority-icon ${priorityConfig[priority].color}`} title={`Priority: ${priority}`}>
            <PriorityIcon size={16} aria-hidden="true" />
          </span>
          <span className="attachment-info" title={`${attachments} attachment${attachments !== 1 ? 's' : ''}`}>
            <Paperclip size={16} aria-hidden="true" /> {attachments}
          </span>
          <span className="attachment-info" title={`${pages} page${pages !== 1 ? 's' : ''}`}>
            <FileText size={16} aria-hidden="true" /> {pages}
          </span>
          <span className={`due-date ${dueDateClass}`} title={`Due date: ${formatDate(dueDate)}`}>
            <Calendar size={16} aria-hidden="true" /> {formatDate(dueDate)}
          </span>
        </div>
        <div className="assignee-wrapper">
          <div 
            className="assignee-initials" 
            style={{ backgroundColor: assigneeColor }}
          >
            {getInitials(assignee)}
          </div>
          <span className="assignee-tooltip">{assignee}</span>
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
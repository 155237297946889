import React, { useState, useEffect } from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import './BarChart.css';

const DEFAULT_COLORS = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40'
];

const BarChart = ({
  userDashboardTileSettings,
  tileSettings,
  data,
  animate = true
}) => {
  // State to control animation
  const [shouldAnimate, setShouldAnimate] = useState(false);

  // Set animation flag on initial mount only
  useEffect(() => {
    setShouldAnimate(animate);
    return () => setShouldAnimate(false);
  }, [animate]);

  // Determine colors to use (priority: tileSettings > userDashboardTileSettings > defaults)
  const colors = tileSettings?.colors || 
                userDashboardTileSettings?.defaultColors || 
                DEFAULT_COLORS;

  // Check if chart should be horizontal
  const isHorizontal = tileSettings?.direction === 'horizontal';

  // Format data for recharts
  const chartData = data.labels.map((label, index) => {
    const dataPoint = { name: label };
    data.datasets.forEach((dataset) => {
      dataPoint[dataset.label || 'value'] = dataset.data[index];
    });
    return dataPoint;
  });

  // Determine which color to use for each dataset
  const getDatasetColor = (datasetIndex) => {
    if (data.datasets.length === 1) {
      if (tileSettings.color) {
        return tileSettings.color;
      } else if (tileSettings.colorIndex) {
        return colors[tileSettings.colorIndex % colors.length];
      }
    }
    if (data.datasets[datasetIndex].color) {
      return data.datasets[datasetIndex].color;
    }
    if (typeof data.datasets[datasetIndex].colorIndex === 'number') {
      return colors[data.datasets[datasetIndex].colorIndex % colors.length];
    }
    return colors[datasetIndex % colors.length];
  };

  // Custom legend style
  const renderLegend = (props) => {
    const { payload } = props;
    
    if (data.datasets.length === 1 && !data.datasets[0].label) {
      return null;
    }

    return (
      <ul className="bar-chart-legend">
        {payload.map((entry, index) => (
          <li key={`legend-item-${index}`}>
            <span
              className="legend-color"
              style={{ backgroundColor: entry.color }}
            />
            <span className="legend-label">{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div className="bar-chart-container">
      <ResponsiveContainer width="100%" height="100%">
        <RechartsBarChart 
          data={chartData}
          layout={isHorizontal ? 'vertical' : 'horizontal'}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            type={isHorizontal ? 'number' : 'category'}
            dataKey={isHorizontal ? undefined : "name"}
            tick={{ fontSize: 12 }}
            interval={0}
            angle={isHorizontal ? 0 : -45}
            textAnchor={isHorizontal ? 'middle' : 'end'}
            height={isHorizontal ? 30 : 60}
          />
          <YAxis
            type={isHorizontal ? 'category' : 'number'}
            dataKey={isHorizontal ? "name" : undefined}
            tick={{ fontSize: 12 }}
            width={isHorizontal ? 60 : 30}
          />
          <Tooltip />
          {data.datasets.map((dataset, index) => (
            <Bar
              key={dataset.label || 'value'}
              dataKey={dataset.label || 'value'}
              fill={getDatasetColor(index)}
              stackId={data.datasets.length > 1 ? "stack" : undefined}
              isAnimationActive={shouldAnimate}
              animationBegin={index * 200}
              animationDuration={1000}
            />
          ))}
          {(data.datasets.length > 1 || data.datasets[0].label) && (
            <Legend 
              content={renderLegend}
              verticalAlign="bottom"
              align="center"
            />
          )}
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChart;
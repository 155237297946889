import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useApiWithSessionExpiration } from './useApiWithSessionExpiration';
import { FaTrash, FaEdit, FaPlus, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import './Clients.css';

function Clients({ accessToken, apiBaseUrl, handleSessionExpiration, userData }) {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [newClientName, setNewClientName] = useState('');
  const [newClientEmailDomain, setNewClientEmailDomain] = useState('');
  const [toast, setToast] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);
  const navigate = useNavigate();

  const fetchClients = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apiCall(`${apiBaseUrl}clients?isEnabled=true`, {
        method: 'GET',
        accessToken: accessToken
      });
      setClients(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching clients:', err);
      setError('Failed to fetch clients. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [apiCall, apiBaseUrl, accessToken]);

  useEffect(() => {
    if (accessToken && apiBaseUrl) {
      fetchClients();
    }
  }, [accessToken, apiBaseUrl, fetchClients]);

  const sortedClients = useMemo(() => {
    let sortableClients = [...clients];
    if (sortConfig.key) {
      sortableClients.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
        if (sortConfig.key === 'emailDomain') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableClients;
  }, [clients, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  const handleDeleteClick = (client) => {
    setClientToDelete(client);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await apiCall(`${apiBaseUrl}clients/${clientToDelete.id}`, {
        method: 'DELETE',
        accessToken: accessToken
      });
      setShowConfirmation(false);
      setClientToDelete(null);
      fetchClients();
      showToast('Client successfully deleted', 'success');
    } catch (err) {
      console.error('Error deleting client:', err);
      showToast('Failed to delete client', 'error');
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    setClientToDelete(null);
  };

  const handleAddClient = async () => {
    try {
      await apiCall(`${apiBaseUrl}clients`, {
        method: 'POST',
        accessToken: accessToken,
        data: { name: newClientName, emailDomain: newClientEmailDomain }
      });
      setShowAddDialog(false);
      setNewClientName('');
      setNewClientEmailDomain('');
      fetchClients();
      showToast('Client successfully added', 'success');
    } catch (err) {
      console.error('Error adding client:', err);
      showToast('Failed to add client', 'error');
    }
  };

  const showToast = (message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  };

  const handleEditClick = (clientId, event) => {
    event.preventDefault();
    navigate(`/clients/${clientId}/edit`);
  };

  if (isLoading) {
    return <div className="clients-loading">Loading clients...</div>;
  }

  if (error) {
    return <div className="clients-error">{error}</div>;
  }

  return (
    <div className="clients-container">
      <h2>Clients</h2>
      <div className="table-container">
        {userData?.roles.includes("ADMIN") && (<button onClick={() => setShowAddDialog(true)} className="add-client-button">
          <FaPlus /> Add Client
        </button>)}
        <table className="clients-table">
          <thead>
            <tr>
              <th className="name-column sortable" onClick={() => requestSort('name')}>
                Name {getSortIcon('name')}
              </th>
              <th className="email-domain-column sortable" onClick={() => requestSort('emailDomain')}>
                Email Domain {getSortIcon('emailDomain')}
              </th>
              {userData?.roles.includes("ADMIN") && (<th className="action-column">Action</th>)}
            </tr>
          </thead>
          <tbody>
            {sortedClients.map((client) => (
              <tr key={client.id}>
                <td className="name-column">
                  <Link to={`/clients/${client.id}/reviews`} className="link">{client.name}</Link>
                </td>
                <td className="email-domain-column">{client.emailDomain}</td>
                {userData?.roles.includes("ADMIN") && (<td className="action-column">
                  <button onClick={(e) => handleEditClick(client.id, e)} className="edit-button">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleDeleteClick(client)} className="delete-button">
                    <FaTrash />
                  </button>
                </td>)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showConfirmation && (
        <div className="confirmation-dialog">
          <p>Are you sure you want to delete {clientToDelete?.name}?</p>
          <button onClick={handleConfirmDelete}>Yes</button>
          <button onClick={handleCancelDelete}>No</button>
        </div>
      )}

      {showAddDialog && (
        <div className="add-client-dialog">
          <h3>Add New Client</h3>
          <input
            type="text"
            value={newClientName}
            onChange={(e) => setNewClientName(e.target.value)}
            placeholder="Enter client name"
          />
          <input
            type="text"
            value={newClientEmailDomain}
            onChange={(e) => setNewClientEmailDomain(e.target.value)}
            placeholder="Enter email domain"
          />
          <button onClick={handleAddClient}>Add</button>
          <button onClick={() => setShowAddDialog(false)}>Cancel</button>
        </div>
      )}

      {toast && (
        <div className={`toast ${toast.type}`}>
          {toast.message}
        </div>
      )}
    </div>
  );
}

export default Clients;
import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FaFilePdf, FaFileWord, FaFileExcel, FaFilePowerpoint, FaFile } from 'react-icons/fa';
import './FileTable.css';

const TypeFilterDropdown = ({ anchorRef, enabledTypes, onToggleType, onClose }) => {
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const dropdownRef = useRef(null);
  
    useEffect(() => {
      if (anchorRef.current) {
        const rect = anchorRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom,
          left: rect.left,
          position: 'fixed'
        });
      }
  
      const handleClickOutside = (event) => {
        if (dropdownRef.current && 
            !dropdownRef.current.contains(event.target) && 
            !anchorRef.current.contains(event.target)) {
          onClose();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [anchorRef, onClose]);
  
    const handleCheckboxChange = (e, type) => {
      e.preventDefault();
      e.stopPropagation();
      onToggleType(type);
    };
  
    const handleLabelClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
  
    return createPortal(
      <div 
        ref={dropdownRef}
        className="type-filter-dropdown"
        style={{
          position: 'fixed',
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          zIndex: 10000
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {Object.entries(enabledTypes).map(([type, enabled]) => (
          <label 
            key={type} 
            className="type-filter-option"
            onClick={handleLabelClick}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <input
              type="checkbox"
              checked={enabled}
              onChange={(e) => handleCheckboxChange(e, type)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
            <span>{type}</span>
          </label>
        ))}
      </div>,
      document.body
    );
  };

const getFileIcon = (mimeType) => {
  switch (mimeType) {
    case 'application/pdf':
      return <FaFilePdf style={{ color: '#dc2626' }} />;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <FaFileWord style={{ color: '#2563eb' }} />;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FaFileExcel style={{ color: '#16a34a' }} />;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return <FaFilePowerpoint style={{ color: '#ea580c' }} />;
    default:
      return <FaFile style={{ color: '#4b5563' }} />;
  }
};

const getFileType = (mimeType) => {
  switch (mimeType) {
    case 'application/pdf':
      return 'PDF';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'Word';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'Excel';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'PowerPoint';
    default:
      return 'Other';
  }
};

export default function FileTable({ files }) {
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [enabledTypes, setEnabledTypes] = useState({
    PDF: true,
    Excel: true,
    Word: false,
    PowerPoint: false,
    Other: false
  });
  const filterRef = useRef(null);

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const toggleTypeFilter = (type) => {
    setEnabledTypes(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  };

  const sortedAndFilteredFiles = [...files]
    .filter(file => {
      const fileType = getFileType(file.mimeType);
      return enabledTypes[fileType];
    })
    .sort((a, b) => {
      let comparison = 0;
      switch (sortField) {
        case 'name':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'createdAt':
          comparison = new Date(a.createdAt) - new Date(b.createdAt);
          break;
        case 'pages':
          comparison = a.pages - b.pages;
          break;
        default:
          comparison = 0;
      }
      return sortDirection === 'asc' ? comparison : -comparison;
    });

  const countedFiles = {'PDF': 0, 'Excel': 0, 'Word': 0, 'PowerPoint': 0, 'Other': 0};
  files.forEach(file => {
    const fileType = getFileType(file.mimeType);
    countedFiles[fileType]++;
  });
  const hiddenFilesMessage = 'Hidden files: ';
  const hiddenFiles = Object.entries(countedFiles)
    .filter(([type, count]) => !enabledTypes[type] && count > 0)
    .map(([type, count]) => `${count} ${type}`)
    .join(', ');
  const message = hiddenFilesMessage + hiddenFiles + ' (click Type to change filter)';
  const hiddenFilesCount = Object.entries(countedFiles)
    .filter(([type, count]) => !enabledTypes[type])
    .reduce((acc, [type, count]) => acc + count, 0);

  return (
    <div className="file-table-container">
      <table className="file-table">
        <thead>
          <tr>
            <th style={{ width: '5%' }} ref={filterRef}>
              <div className="type-filter">
                <button
                  className="type-filter-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTypeFilter(!showTypeFilter)
                }}
                >
                  Type
                </button>
                {showTypeFilter && (
                  <TypeFilterDropdown
                    anchorRef={filterRef}
                    enabledTypes={enabledTypes}
                    onToggleType={toggleTypeFilter}
                    onClose={() => setShowTypeFilter(false)}
                  />
                )}
              </div>
            </th>
            <th 
              style={{ width: '76%' }} 
              onClick={() => handleSort('name')}
            >
              Name
              {sortField === 'name' && (
                <span className={`sort-indicator ${sortDirection}`} />
              )}
            </th>
            <th 
              style={{ width: '12%' }} 
              onClick={() => handleSort('createdAt')}
            >
              Created
              {sortField === 'createdAt' && (
                <span className={`sort-indicator ${sortDirection}`} />
              )}
            </th>
            <th 
              style={{ width: '7%' }} 
              onClick={() => handleSort('pages')}
            >
              Pages
              {sortField === 'pages' && (
                <span className={`sort-indicator ${sortDirection}`} />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedAndFilteredFiles.map((file) => (
            <tr key={file.id}>
              <td className="icon-cell">
                {getFileIcon(file.mimeType)}
              </td>
              <td>
                <a
                  href={file.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-link"
                >
                  {file.name}
                </a>
              </td>
              <td className="created-cell">
                {new Date(file.createdAt).toLocaleDateString()}<br />
                {new Date(file.createdAt).toLocaleTimeString([], { 
                  hour: 'numeric', 
                  minute: '2-digit',
                  hour12: true 
                })}
              </td>
              <td className="pages-cell">
                {file.pages}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="hidden-files-message">
        {hiddenFilesCount > 0 && message}
      </div>
    </div>
  );
}
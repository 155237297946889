import React from 'react';
import { Link } from 'react-router-dom';
import './ClientTabs.css';

function ClientTabs({ clientId, activeTab }) {
  return (
    <div className="client-tabs">
      <Link 
        to={`/clients/${clientId}/reviews`} 
        className={`tab ${activeTab === 'reviews' ? 'active' : ''}`}
      >
        Recent Reviews
      </Link>
      <Link 
        to={`/clients/${clientId}/completedreviews`} 
        className={`tab ${activeTab === 'completedreviews' ? 'active' : ''}`}
      >
        Completed Reviews
      </Link>
      <Link 
        to={`/clients/${clientId}/edit`} 
        className={`tab ${activeTab === 'edit' ? 'active' : ''}`}
      >
        Configuration
      </Link>
    </div>
  );
}

export default ClientTabs;
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApiWithSessionExpiration } from './useApiWithSessionExpiration';

function OAuthCallback({ accessToken, handleSessionExpiration }) {
  const navigate = useNavigate();
  const location = useLocation();
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');
      
      if (code && state) {
        try {
          // Parse the state parameter which contains configId and clientId
          const { configId, clientId, redirectUri, apiBaseUrl } = JSON.parse(atob(state));
          
          await apiCall(`${apiBaseUrl}oauth/exchange`, {
            method: 'POST',
            accessToken: accessToken,
            data: { configId, code, redirectUri }
          });
          
          // Navigate back to the client edit page
          navigate(`/clients/${clientId}/edit`, { 
            state: { 
              integrationSuccess: true,
              message: 'Authentication successful'
            }
          });
        } catch (err) {
          console.error('Error in OAuth callback:', err);
          // Try to extract clientId from state even if exchange failed
          try {
            const { clientId } = JSON.parse(atob(state));
            navigate(`/clients/${clientId}/edit`, {
              state: {
                integrationError: true,
                message: 'Authentication failed'
              }
            });
          } catch (parseErr) {
            // If we can't even parse the state, go to clients list
            navigate('/clients', {
              state: {
                integrationError: true,
                message: 'Authentication failed'
              }
            });
          }
        }
      } else {
        navigate('/clients', {
          state: {
            integrationError: true,
            message: 'Invalid OAuth callback'
          }
        });
      }
    };

    handleCallback();
  }, [navigate, location, apiCall, accessToken]);

  return (
    <div className="oauth-callback-loading">
      <p>Processing authentication...</p>
    </div>
  );
}

export default OAuthCallback;
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import Spinner from 'react-spinner-material';
import DOMPurify from 'dompurify';
import { useChat } from './ChatContext';
import './Chat.css';

function Chat({ accessToken, apiBaseUrl, handleSessionExpiration, userData }) {
  const { chatHistories, addMessage } = useChat();
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const environment = useMemo(() => 
    apiBaseUrl.includes('localhost') || apiBaseUrl.includes('127.0.0.1') ? 'local' : 'production',
    [apiBaseUrl]
  );

  const messages = useMemo(() => 
    chatHistories[environment] || [],
    [chatHistories, environment]
  );

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const parseCSV = (csvContent) => {
    const rows = [];
    let currentRow = [];
    let currentCell = '';
    let withinQuotes = false;

    for (let i = 0; i < csvContent.length; i++) {
      const char = csvContent[i];
      const nextChar = csvContent[i + 1];

      if (char === '"' && !withinQuotes) {
        withinQuotes = true;
        continue;
      }

      if (char === '"' && withinQuotes) {
        if (nextChar === '"') {
          currentCell += '"';
          i++; // Skip next quote
        } else {
          withinQuotes = false;
        }
        continue;
      }

      if (char === ',' && !withinQuotes) {
        currentRow.push(currentCell.trim());
        currentCell = '';
        continue;
      }

      if (char === '\n' && !withinQuotes) {
        currentRow.push(currentCell.trim());
        rows.push(currentRow);
        currentRow = [];
        currentCell = '';
        continue;
      }

      currentCell += char;
    }

    // Push the last cell and row if there's any
    if (currentCell) {
      currentRow.push(currentCell.trim());
    }
    if (currentRow.length > 0) {
      rows.push(currentRow);
    }

    return rows;
  };

  const formatMessage = (text) => {
    const tableRegex = /<table>([\s\S]*?)<\/table>/;
    const match = text.match(tableRegex);

    if (match) {
      const tableContent = match[1].trim();
      const rows = parseCSV(tableContent);
      const [headers, ...data] = rows;

      return (
        <>
          <p>{text.substring(0, match.index).trim()}</p>
          <div className="csv-table-container">
            <table className="csv-table">
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(header.trim()),
                      }}
                    />
                  ))}
                </tr>
              </thead>
              <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(cell.trim()),
                      }}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
            </table>
          </div>
          <p>{text.substring(match.index + match[0].length).trim()}</p>
        </>
      );
    }
    const lines = text.split('\n');
    return lines.map((line, lineIndex) => {
      const parts = line.split(/(\*\*.*?\*\*)/g);
      return (
        <React.Fragment key={lineIndex}>
          {parts.map((part, partIndex) => {
            if (part.startsWith('**') && part.endsWith('**')) {
              return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
            }
            return (
              <span
                key={partIndex}
                dangerouslySetInnerHTML={{__html: part}}
              />
            );
          })}
          {lineIndex !== lines.length - 1 && <br />}
        </React.Fragment>
      );
    });
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return null;
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) return null; // Invalid date
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    addMessage(environment, { text: input, type: 'user' });
    setInput('');
    setIsLoading(true);

    try {
      const response = await axios.post(`${apiBaseUrl}question`, { question: input }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      addMessage(environment, { text: response.data.answer, type: 'api' });
    } catch (error) {
      console.error('Error calling API:', error);
      if (error.response && error.response.status === 401) {
        addMessage(environment, { text: 'Error: Unauthorized, Please Log-in', type: 'error' });
        handleSessionExpiration();
      } else if (error.response && error.response.status.toString().startsWith('5')) {
        addMessage(environment, { text: 'Error: There was a server error. Try a different question, if you continue to get this message you may need to try again later.', type: 'error' });
      } else if (error.response && error.response.status.toString().startsWith('4') && error.response.data) {
        addMessage(environment, { text: error.response.data, type: 'error' });
      } else {
        addMessage(environment, { text: 'Error: There was an error handling your question. Please try asking a different question or try again later.', type: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="app">
      <div className="message-container">
        {messages.map((message, index) => (
          <div key={index} className={`message-wrapper ${message.type}`}>
            {message.type === 'user' && formatTimestamp(message.timestamp) && (
              <div className="message-timestamp">{formatTimestamp(message.timestamp)}</div>
            )}
            <div className={`message ${message.type}`}>
              {formatMessage(message.text)}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="message api loading">
            <Spinner radius={20} color={"#333"} stroke={2} visible={true} />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="What would you like to know?"
        />
        <button className="submit-button" type="submit">Send</button>
      </form>
    </div>
  );
}

export default Chat;
import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import './LineChart.css';

const DEFAULT_COLORS = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40'
];

const CustomLineChart = ({
  userDashboardTileSettings,
  tileSettings,
  data,
  animate = true
}) => {
  // State to control animation
  const [shouldAnimate, setShouldAnimate] = useState(false);

  // Set animation flag on initial mount only
  useEffect(() => {
    setShouldAnimate(animate);
    return () => setShouldAnimate(false);
  }, [animate]);

  // Determine colors to use (priority: tileSettings > userDashboardTileSettings > defaults)
  const colors = tileSettings?.colors || 
                userDashboardTileSettings?.defaultColors || 
                DEFAULT_COLORS;

  // Format data for recharts
  const chartData = data.labels.map((label, index) => {
    const dataPoint = { name: label };
    data.datasets.forEach((dataset) => {
      dataPoint[dataset.label || 'value'] = dataset.data[index];
    });
    return dataPoint;
  });

  // Determine which color to use for each dataset
  const getDatasetColor = (datasetIndex) => {
    if (data.datasets.length === 1) {
      if (tileSettings.color) {
        return tileSettings.color;
      } else if (tileSettings.colorIndex) {
        return colors[tileSettings.colorIndex % colors.length];
      }
    }
    if (data.datasets[datasetIndex].color) {
      return data.datasets[datasetIndex].color;
    }
    if (typeof data.datasets[datasetIndex].colorIndex === 'number') {
      return colors[data.datasets[datasetIndex].colorIndex % colors.length];
    }
    return colors[datasetIndex % colors.length];
  };

  // Custom legend style
  const renderLegend = (props) => {
    const { payload } = props;
    
    if (data.datasets.length === 1 && !data.datasets[0].label) {
      return null;
    }

    return (
      <ul className="line-chart-legend">
        {payload.map((entry, index) => (
          <li key={`legend-item-${index}`}>
            <span
              className="legend-color"
              style={{ backgroundColor: entry.color }}
            />
            <span className="legend-label">{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="line-chart-container">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="name"
            tick={{ fontSize: 12 }}
            interval={0}
            angle={-45}
            textAnchor="end"
            height={50}
          />
          <YAxis 
            tick={{ fontSize: 12 }}
            width={30}
          />
          <Tooltip />
          {data.datasets.map((dataset, index) => (
            <Line
              key={dataset.label || 'value'}
              type="monotone"
              dataKey={dataset.label || 'value'}
              stroke={getDatasetColor(index)}
              strokeWidth={2}
              dot={{ r: 4, fill: getDatasetColor(index) }}
              activeDot={{ r: 6 }}
              isAnimationActive={shouldAnimate}
              animationBegin={index * 200}
              animationDuration={1500}
            />
          ))}
          {(data.datasets.length > 1 || data.datasets[0].label) && (
            <Legend 
              content={renderLegend}
              verticalAlign="bottom"
              align="center"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
import React, { useState, useEffect, useRef } from 'react';
import './DashboardTable.css';

const Tooltip = ({ content, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef(null);

  const handleMouseEnter = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setPosition({
      x: rect.left,
      y: rect.bottom + window.scrollY
    });
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div
          ref={tooltipRef}
          className="custom-tooltip"
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

const DashboardTable = ({
    userDashboardTileSettings,
    tileSettings,
    data,
    animate = true
  }) => {
    const [sortConfig, setSortConfig] = useState({
      key: null,
      direction: 'asc'
    });
    const [sortedData, setSortedData] = useState([]);
    const tableRef = useRef(null);
  
    useEffect(() => {
      if (tableRef.current && data.labels) {
        tableRef.current.style.setProperty('--column-count', data.labels.length);
      }
    }, [data.labels]);
  
    const handleSort = (key) => {
      let direction = 'asc';
      if (sortConfig.key === key) {
        direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
      }
      setSortConfig({ key, direction });
    };
  
    useEffect(() => {
      const formatTableData = () => {
        const hasLabels = data.datasets.some(dataset => dataset.label);
        return data.datasets.map((dataset, index) => {
          const row = { 
            id: index,
            rowLabel: hasLabels ? dataset.label || `Row ${index + 1}` : undefined,
            ...dataset.data.reduce((acc, value, colIndex) => {
              acc[data.labels[colIndex]] = value;
              return acc;
            }, {})
          };
          return row;
        });
      };
      let sortedRows = [...formatTableData()];
      if (sortConfig.key) {
        sortedRows.sort((a, b) => {
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];
  
          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
          }
  
          if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0;
        });
      }
      setSortedData(sortedRows);
    }, [sortConfig, data]);
  
    const getSortIndicator = (columnName) => {
      if (sortConfig.key === columnName) {
        return (
          <span className={`sort-indicator ${sortConfig.direction}`} />
        );
      }
      return null;
    };
  
    const CellContent = ({ content, className = '' }) => {
      const cellRef = useRef(null);
      const [needsTooltip, setNeedsTooltip] = useState(false);
  
      useEffect(() => {
        if (cellRef.current) {
          const isTextTruncated = cellRef.current.scrollWidth > cellRef.current.clientWidth;
          setNeedsTooltip(isTextTruncated);
        }
      }, [content]);
  
      const contentElement = (
        <div ref={cellRef} className={`cell-content ${className}`}>
          {content}
        </div>
      );
  
      if (needsTooltip) {
        return <Tooltip content={content}>{contentElement}</Tooltip>;
      }
  
      return contentElement;
    };
  
    const hasLabels = data.datasets.some(dataset => dataset.label);
  
    return (
      <div className="dashboard-table-container">
        <div className="table-wrapper">
          <table ref={tableRef} className="dashboard-table">
            <thead>
              <tr>
                {hasLabels && (
                  <th 
                    onClick={() => handleSort('rowLabel')}
                    className={sortConfig.key === 'rowLabel' ? `sort-${sortConfig.direction}` : ''}
                  >
                    <CellContent 
                      content={tileSettings?.rowLabel || 'Label'}
                      className="header-content"
                    />
                    {getSortIndicator('rowLabel')}
                  </th>
                )}
                {data.labels.map((label, index) => (
                  <th 
                    key={index}
                    onClick={() => handleSort(label)}
                    className={sortConfig.key === label ? `sort-${sortConfig.direction}` : ''}
                    style={{ width: `${60 / data.labels.length}%` }}
                  >
                    <CellContent 
                      content={label}
                      className="header-content"
                    />
                    {getSortIndicator(label)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((row) => (
                <tr key={row.id}>
                  {hasLabels && (
                    <td>
                      <CellContent content={row.rowLabel} />
                    </td>
                  )}
                  {data.labels.map((label, colIndex) => (
                    <td 
                      key={colIndex}
                      style={{ width: `${60 / data.labels.length}%` }}
                    >
                      <CellContent 
                        content={typeof row[label] === 'number' 
                          ? Number(row[label]).toLocaleString(undefined, {
                              maximumFractionDigits: 2
                            })
                          : row[label]
                        }
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

export default DashboardTable;
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useApiWithSessionExpiration } from './useApiWithSessionExpiration';
import ClientTabs from './ClientTabs';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formatInTimeZone } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import TaskDetails from './TaskDetails';
import Popup from './Popup';
import './ClientReviews.css';
import formatTitle from './format_title';

function ClientReviews({ accessToken, apiBaseUrl, handleSessionExpiration, userData }) {
  const { id } = useParams();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  useEffect(() => {
    const fetchReviews = async () => {
      if (isFirstLoad) {
        setIsFirstLoad(false);
        setIsLoading(true);
      }
      try {
        if (!selectedTask) {
          const response = await apiCall(`${apiBaseUrl}clients/${id}/reviews`, {
            method: 'GET',
            accessToken: accessToken
          });
          setReviews(response.data);
          setError(null);
        }
      } catch (err) {
        console.error('Error fetching reviews:', err);
        setError('Failed to fetch reviews. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, [id, apiCall, apiBaseUrl, accessToken, selectedTask, isFirstLoad]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return formatInTimeZone(parseISO(dateString), Intl.DateTimeFormat().resolvedOptions().timeZone, 'MM/dd hh:mmaaa');
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task.id);
  };

  const closePopup = () => {
    setSelectedTask(null);
  };

  const renderChart = () => {
    if (!reviews || !reviews.weeklyTaskCounts) return null;

    const chartData = reviews.weeklyTaskCounts
      .map(item => ({
        week: formatInTimeZone(parseISO(item.week), 'UTC', 'MM/dd'),
        count: item.taskCount,
        originalDate: item.week
      }))
      .sort((a, b) => new Date(a.originalDate) - new Date(b.originalDate));

    return (
      <div>
        <h3>Reviews Requested Per Week</h3>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <BarChart
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" name="Review Count" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  const renderTable = (title, tasks, columns) => {
    if (tasks.length === 0) return null;
    return (
      <div>
        <h3>{title}</h3>
        <table>
          <thead>
            <tr>
              {columns.map(column => <th key={column}>{column}</th>)}
            </tr>
          </thead>
          <tbody>
            {tasks.map(task => (
              <tr key={task.id} onClick={() => handleTaskClick(task)} style={{cursor: 'pointer'}}>
                {columns.map(column => {
                  let value;
                  switch(column.toLowerCase()) {
                    case 'marketing piece':
                      value = formatTitle(task.title);
                      break;
                    case 'created':
                      value = formatDate(task.createdAt);
                      break;
                    case 'due':
                      value = formatDate(task.dueDate);
                      break;
                    case 'completed':
                      value = formatDate(task.completedDate);
                      break;
                    case 'state':
                      value = task.state.replace(/_/g, ' ');
                      break;
                    case 'assigned to':
                      value = task.assignedTo?.person?.displayName || 'Unassigned';
                      break;
                    default:
                      value = task[column.toLowerCase()] || 'N/A';
                  }
                  return <td key={`${task.id}-${column}`}>{value}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderReviews = () => {
    if (!reviews || !reviews.recentTasks) {
      return <p>No review data available.</p>;
    }

    const openReviews = reviews.recentTasks.filter(task => 
      ['OPEN', 'ASSIGNED', 'IN_PROGRESS'].includes(task.state)
    );
    const pendingClientReviews = reviews.recentTasks.filter(task => 
      task.state === 'PENDING_CLIENT'
    );
    const completedReviews = reviews.recentTasks.filter(task => 
      task.state === 'COMPLETED'
    );

    return (
      <>
        {renderTable("Open Reviews", openReviews, ["Marketing Piece", "Created", "Due", "State", "Assigned To"])}
        {renderTable("Reviews Waiting for Client Response to Comments", pendingClientReviews, ["Marketing Piece", "Created", "Due", "Assigned To"])}
        {renderTable("Recently Completed Reviews", completedReviews, ["Marketing Piece", "Created", "Due", "Completed", "Assigned To"])}
        {openReviews.length === 0 && pendingClientReviews.length === 0 && completedReviews.length === 0 && 
          <p>No recent reviews to display.</p>
        }
        <div className="view-all-completed">
          <Link to={`/clients/${id}/completedreviews`}>View All Completed Reviews</Link>
        </div>
      </>
    );
  };

  return (
    <div className="client-reviews">
      <div className="client-tabs-container">
        <ClientTabs clientId={id} activeTab="reviews" />
      </div>
      {isLoading ? (
        <div className="loading-container">
          <p>Loading reviews...</p>
        </div>
      ) : error ? (
        <div className="error-container">
          <p>{error}</p>
        </div>
      ) : (
        <div className="client-reviews-content">
          <h2>Recent Reviews for {reviews?.client?.name}</h2>
          {renderChart()}
          {renderReviews()}
        </div>
      )}
      {selectedTask && (
        <Popup onClose={closePopup}>
          <TaskDetails
            accessToken={accessToken}
            apiBaseUrl={apiBaseUrl}
            handleSessionExpiration={handleSessionExpiration}
            taskId={selectedTask}
            isPopup={true}
            isAdmin ={userData?.roles.includes('ADMIN') || false}
            onClose={closePopup}
          />
        </Popup>
      )}
    </div>
  );
}

export default ClientReviews;
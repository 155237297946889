import React, { useState, useEffect } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import './DonutChart.css';

const DEFAULT_COLORS = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40'
];

const DonutChart = ({ 
  userDashboardTileSettings, 
  tileSettings, 
  data,
  animate = true 
}) => {
  // State to control animation
  const [shouldAnimate, setShouldAnimate] = useState(false);
  
  // Set animation flag on initial mount only
  useEffect(() => {
    setShouldAnimate(animate);
    // Cleanup function to reset animation state when component unmounts
    return () => setShouldAnimate(false);
  }, [animate]); // Empty dependency array means this runs once on mount

  // Determine colors to use (priority: tileSettings > userDashboardTileSettings > defaults)
  const colors = tileSettings?.colors || 
                userDashboardTileSettings?.defaultColors || 
                DEFAULT_COLORS;

  // Transform the data into the format Recharts expects
  const chartData = data.labels.map((label, index) => ({
    name: label,
    value: data.datasets[0].data[index]
  }));

  // Custom legend renderer to match the original style
  const renderLegend = (props) => {
    const { payload } = props;
    
    return (
      <ul className="donut-chart-legend">
        {payload.map((entry, index) => (
          <li key={`legend-item-${index}`}>
            <span 
              className="legend-color"
              style={{ backgroundColor: entry.color }}
            />
            <span className="legend-label">{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="donut-chart-container">
      <ResponsiveContainer width="100%" height="95%">
        <PieChart>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            innerRadius="60%"
            outerRadius="100%"
            dataKey="value"
            isAnimationActive={shouldAnimate}
            animationBegin={0}
            animationDuration={1000}
          >
            {chartData.map((entry, index) => (
              <Cell 
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
                stroke="white"
                strokeWidth={1}
              />
            ))}
          </Pie>
          <Tooltip 
            isAnimationActive={false}
          />
          {tileSettings?.displayLegend !== false && (
            <Legend 
              content={renderLegend}
              verticalAlign="bottom"
              align="center"
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DonutChart;
import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatHistories, setChatHistories] = useState({
    local: [],
    production: []
  });

  useEffect(() => {
    // Load chat histories from localStorage when the app starts
    const storedLocalHistory = localStorage.getItem('chatHistory_local');
    const storedProductionHistory = localStorage.getItem('chatHistory_production');

    setChatHistories({
      local: storedLocalHistory ? JSON.parse(storedLocalHistory) : [],
      production: storedProductionHistory ? JSON.parse(storedProductionHistory) : []
    });
  }, []);

  const updateChatHistory = useCallback((environment, messages) => {
    setChatHistories(prevHistories => {
      const newHistories = { ...prevHistories, [environment]: messages };
      localStorage.setItem(`chatHistory_${environment}`, JSON.stringify(messages));
      return newHistories;
    });
  }, []);

  const addMessage = useCallback((environment, message) => {
    setChatHistories(prevHistories => {
      const timestamp = new Date().toISOString();
      const updatedMessage = { ...message, timestamp };
      const updatedMessages = [...prevHistories[environment], updatedMessage];
      localStorage.setItem(`chatHistory_${environment}`, JSON.stringify(updatedMessages));
      return { ...prevHistories, [environment]: updatedMessages };
    });
  }, []);

  const clearChatHistories = useCallback(() => {
    setChatHistories({ local: [], production: [] });
    localStorage.removeItem('chatHistory_local');
    localStorage.removeItem('chatHistory_production');
  }, []);

  return (
    <ChatContext.Provider value={{ chatHistories, updateChatHistory, addMessage, clearChatHistories }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
import React, { useEffect, useState, useCallback } from 'react';

const Profile = ({ accessToken, apiCall, apiBaseUrl }) => {
  const [userData, setUserData] = useState(null);

  const fetchUserData = useCallback(async (token) => {
    try {
      const response = await apiCall(`${apiBaseUrl}getAuthUserData`, {
        method: 'GET',
        accessToken: token
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [apiCall, apiBaseUrl]);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden && accessToken) {
      fetchUserData(accessToken);
    }
  }, [accessToken, fetchUserData]);

  useEffect(() => {
    let intervalId = null;

    if (accessToken) {
      // Initial fetch
      fetchUserData(accessToken);

      // Set up polling
      intervalId = setInterval(handleVisibilityChange, 300000);

      // Set up visibility change listener
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } 

    return () => {
      if (intervalId) clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [accessToken, fetchUserData, handleVisibilityChange]);

  return (
    <div className="profile-info">
      <p>{userData ? userData.displayName : "Loading..."}</p>
      {/* Add any additional profile information you want to display */}
    </div>
  );
};

export default Profile;
import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import TaskCard from './TaskCard';
import TaskDetails from './TaskDetails';
import Popup from './Popup';
import { useApiWithSessionExpiration } from './useApiWithSessionExpiration';
import EmployeeFilter from './EmployeeFilter';
import ClientFilter from './ClientFilter';
import AddTask from './AddTask';
import { FaPlus } from 'react-icons/fa';
import './TaskBoard.css';

const STATES = ['OPEN', 'ASSIGNED', 'IN_PROGRESS', 'PENDING_CLIENT', 'COMPLETED', 'CANCELLED', 'INTERNAL_REVIEW'];

const TaskBoard = ({ accessToken, apiBaseUrl, handleSessionExpiration, userData }) => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filter, setFilter] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [error, setError] = useState(null);
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);
  const [showAddTaskPopup, setShowAddTaskPopup] = useState(false);

  const fetchTasks = useCallback(async () => {
    try {
      if (!selectedTask) {
        const response = await apiCall(`${apiBaseUrl}taskboard`, {
          method: 'GET',
          accessToken: accessToken,
        });
        const sortedTasks = sortTasksByDueDate(response.data);
        setTasks(sortedTasks);
        setFilteredTasks(sortedTasks); // Initialize filteredTasks with all tasks
        setError(null);
      }
    } catch (err) {
      console.error('Error fetching tasks:', err);
      setError('Failed to fetch tasks. Please try again later.');
    }
  }, [apiCall, apiBaseUrl, accessToken, selectedTask]);

  useEffect(() => {
    fetchTasks();
    const intervalId = setInterval(fetchTasks, 60000); // Refresh every 60 seconds
    return () => clearInterval(intervalId);
  }, [fetchTasks]);

  useEffect(() => {
    const filtered = tasks.filter(task => 
      (task.title.toLowerCase().includes(filter.toLowerCase()) ||
       task.assignedEmployeeName.toLowerCase().includes(filter.toLowerCase())) &&
      (selectedEmployees.length === 0 || selectedEmployees.includes(task.assignedEmployeeName)) &&
      (selectedClients.length === 0 || selectedClients.includes(task.clientName))
    );
    setFilteredTasks(filtered);
  }, [tasks, filter, selectedEmployees, selectedClients]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;
  
    const { draggableId, destination } = result;
    const task = tasks.find(t => t.id.toString() === draggableId);
    const newState = destination.droppableId;

    // Make API call
    try {
      const response = await apiCall(`${apiBaseUrl}taskboard`, {
        method: 'PUT',
        accessToken: accessToken,
        data: { id: task.id, state: newState },
      });
      const responseData = sortTasksByDueDate(response.data);
      setTasks(responseData);
      setFilteredTasks(responseData.filter(t => 
        t.title.toLowerCase().includes(filter.toLowerCase()) ||
        t.assignedEmployeeName.toLowerCase().includes(filter.toLowerCase())
      ));
    } catch (err) {
      console.error('Error updating task state:', err);
      setError('Failed to update task state. Please try again.');
      fetchTasks();
    }
  };

  const sortTasksByDueDate = (tasksToSort) => {
    return [...tasksToSort].sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  };

  const handleTaskClick = (taskId) => {
    setSelectedTask(taskId);
  };

  const closePopup = () => {
    setSelectedTask(null);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  const handleEmployeeFilterChange = (selectedEmployees) => {
    setSelectedEmployees(selectedEmployees);
  };

  const handleClientFilterChange = (selectedClients) => {
    setSelectedClients(selectedClients);
  };

  return (
    <div className="task-board">
      <h2>Marketing Review Board</h2>
      <div className="filter-container">
        <input
          type="text"
          placeholder="Filter by title..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="filter-input"
        />
        <EmployeeFilter tasks={tasks} onFilterChange={handleEmployeeFilterChange} />
        <ClientFilter tasks={tasks} onFilterChange={handleClientFilterChange} />
        <button className="add-task-button" onClick={() => setShowAddTaskPopup(true)}>
          <FaPlus /> Add Task
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="columns-container">
          {STATES.map((state) => (
            <StrictModeDroppable droppableId={state} key={state}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`column ${state.toLowerCase().replace('_', '-')}`}
                >
                  <h2>{state.replace('_', ' ')}</h2>
                  <div className="column-content">
                    {filteredTasks
                      .filter(task => task.state === state)
                      .sort((a, b) => {
                        if (state === 'IN_PROGRESS') {
                          if (a.completedDate && !b.completedDate) return 1;
                          if (!a.completedDate && b.completedDate) return -1;
                          if (a.completedDate && b.completedDate) {
                            if (a.clientResponseDate && b.clientResponseDate) {
                              const aClientResponseDelay = new Date(a.clientResponseDate) - new Date(a.completedDate);
                              const bClientResponseDelay = new Date(b.clientResponseDate) - new Date(b.completedDate);
                              return aClientResponseDelay - bClientResponseDelay;
                            } else {
                              return new Date(b.completedDate) - new Date(a.completedDate);
                            }
                          }
                          return new Date(a.dueDate) - new Date(b.dueDate);
                        }
                        if (state === 'COMPLETED') {
                          return new Date(b.completedDate) - new Date(a.completedDate);
                        }
                        return new Date(a.dueDate) - new Date(b.dueDate);
                      })
                      .map((task, index) => (
                        <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() => handleTaskClick(task.id)}
                            >
                              <TaskCard
                                client={task.clientName}
                                title={task.title}
                                priority={task.priority}
                                attachments={task.numberOfFiles || 0}
                                dueDate={task.dueDate}
                                assignee={task.assignedEmployeeName}
                                pages={task.numberOfPages}
                                state={task.state}
                                completedDate={task.completedDate}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </StrictModeDroppable>
          ))}
        </div>
      </DragDropContext>
      {selectedTask && (
        <Popup onClose={closePopup}>
          <TaskDetails
            accessToken={accessToken}
            apiBaseUrl={apiBaseUrl}
            handleSessionExpiration={handleSessionExpiration}
            taskId={selectedTask}
            isPopup={true}
            isAdmin ={userData?.roles.includes('ADMIN') || false}
            onClose={closePopup}
            userData={userData}
          />
        </Popup>
      )}
      {showAddTaskPopup && (
        <Popup onClose={() => setShowAddTaskPopup(false)}>
          <AddTask
            accessToken={accessToken}
            apiBaseUrl={apiBaseUrl}
            handleSessionExpiration={handleSessionExpiration}
            onClose={() => {
              setShowAddTaskPopup(false);
              fetchTasks();
            }}
          />
        </Popup>
      )}
    </div>
  );
};

export default TaskBoard;
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useApiWithSessionExpiration } from './useApiWithSessionExpiration';
import { formatInTimeZone } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import { FaQuestionCircle, FaTrash, FaChevronDown, FaPlus, FaPaperclip } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TaskDetails.css';
import AddFiles from './AddFiles';
import Popup from './Popup';
import FileTable from './FileTable';

function TaskDetails({ accessToken, apiBaseUrl, handleSessionExpiration, taskId: propTaskId, isPopup = false, isAdmin = false, onClose, userData }) {
  const { taskId: paramTaskId } = useParams();
  const taskId = propTaskId || paramTaskId;
  const [task, setTask] = useState(null);
  const [originalDueDate, setOriginalDueDate] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDueDateReason, setShowDueDateReason] = useState(false);
  const [expandedEmails, setExpandedEmails] = useState({});
  const [employees, setEmployees] = useState([]);
  const [numberOfPagesChanged, setNumberOfPagesChanged] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [clients, setClients] = useState([]);
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  const [showAddNotePopup, setShowAddNotePopup] = useState(false);
  const [newNote, setNewNote] = useState('');
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);
  const [showAddFilesPopup, setShowAddFilesPopup] = useState(false);

  const fetchTaskDetails = useCallback(async () => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      setIsLoading(true);
    }
    try {
      const response = await apiCall(`${apiBaseUrl}tasks/${taskId}`, {
        method: 'GET',
        accessToken: accessToken
      });
      setTask(response.data);
      setOriginalDueDate(response.data.dueDate);
      setError(null);
    } catch (err) {
      console.error('Error fetching task details:', err);
      setError('Failed to fetch task details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [taskId, apiCall, apiBaseUrl, accessToken, isFirstLoad]);

  const fetchEmployees = useCallback(async () => {
    try {
      const response = await apiCall(`${apiBaseUrl}employees?isActive=true`, {
        method: 'GET',
        accessToken: accessToken
      });
      setEmployees(response.data);
    } catch (err) {
      console.error('Error fetching employees:', err);
    }
  }, [apiCall, apiBaseUrl, accessToken]);

  const fetchClients = useCallback(async () => {
    try {
      const response = await apiCall(`${apiBaseUrl}clients`, {
        method: 'GET',
        accessToken: accessToken
      });
      setClients(response.data);
    } catch (err) {
      console.error('Error fetching clients:', err);
    }
  }, [apiCall, apiBaseUrl, accessToken]);

  useEffect(() => {
    fetchTaskDetails();
    fetchEmployees();
    if (isAdmin) {
      fetchClients();
    }
  }, [fetchTaskDetails, fetchEmployees, fetchClients, isAdmin]);

  const updateTask = async (updateData) => {
    try {
      await apiCall(`${apiBaseUrl}tasks/${taskId}`, {
        method: 'PUT',
        accessToken: accessToken,
        data: updateData
      });
      fetchTaskDetails();
    } catch (err) {
      console.error('Error updating task:', err);
      setError('Failed to update task. Please try again.');
    }
  };

  const deleteTask = async () => {
    try {
      await apiCall(`${apiBaseUrl}tasks/${taskId}`, {
        method: 'DELETE',
        accessToken: accessToken,
      });
      fetchTaskDetails();
    } catch (err) {
      console.error('Error deleting task:', err);
      setError('Failed to delete task. Please try again.');
    }
  };

  const handleClientChange = async (clientId) => {
    try {
      await updateTask({ clientId });
      setShowClientDropdown(false);
      fetchTaskDetails();
    } catch (err) {
      console.error('Error updating client:', err);
      setError('Failed to update client. Please try again.');
    }
  };

  const handleAssignedToChange = (e) => {
    const assignedToId = e.target.value;
    setTask(prev => ({
      ...prev,
      assignedTo: employees.find(emp => emp.id === assignedToId)
    }));
    updateTask({ assignedToId });
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setTask(prev => ({
      ...prev,
      state
    }));
    updateTask({ state });
  };

  const handlePriorityChange = (e) => {
    const priority = e.target.value;
    setTask(prev => ({
      ...prev,
      priority
    }));
    updateTask({ priority });
  };

  const handleDueDateChange = (date) => {
    const dueDate = date.toISOString();
    setTask(prev => ({
      ...prev,
      dueDate
    }));
  };

  const handleDueDateCalendarClose = () => {
    const dueDate = task.dueDate;
    if (dueDate !== originalDueDate) {
      updateTask({ dueDate });
    }
  };

  const handleTypeChange = (e) => {
    const type = e.target.value;
    setTask(prev => ({
      ...prev,
      type
    }));
    updateTask({ type });
  };

  const handleNumberOfPagesChange = (e) => {
    const numberOfPages = parseInt(e.target.value, 10);
    if (numberOfPages > 0 && numberOfPages < 10000 && numberOfPages !== task.numberOfPages) {
      setTask(prev => ({
        ...prev,
        numberOfPages
      }));
      setNumberOfPagesChanged(true);
    }
  };

  const handleNumberOfPagesBlur = () => {
    const numberOfPages = task.numberOfPages;
    if (numberOfPagesChanged) {
      updateTask({ numberOfPages });
    }
  };

  const handleIsAdvertisementChange = (e) => {
    const isAdvertisement = e.target.value === 'Yes';
    setTask(prev => ({
      ...prev,
      isAdvertisement
    }));
    updateTask({ isAdvertisement });
  };

  const handleIsApprovedChange = (e) => {
    const isApproved = e.target.value === 'Yes';
    setTask(prev => ({
      ...prev,
      approved: isApproved
    }));
    updateTask({ isApproved });
  };

  const handleAddNoteClick = () => {
    setShowAddNotePopup(true);
  };

  const handleCancelNote = () => {
    setShowAddNotePopup(false);
    setNewNote('');
  };

  const handlePostNote = async () => {
    try {
      await apiCall(`${apiBaseUrl}notes`, {
        method: 'POST',
        accessToken: accessToken,
        data: {
          employeeId: userData.employeeId,
          taskId: task.id,
          note: newNote
        }
      });
      setShowAddNotePopup(false);
      setNewNote('');
      fetchTaskDetails();
    } catch (err) {
      console.error('Error posting note:', err);
      setError('Failed to post note. Please try again.');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = parseISO(dateString);
    const format = 'MM/dd/yyyy, h:mm aa zzz';
    return formatInTimeZone(date, 'America/New_York', format);
  };

  const toggleDueDateReason = () => {
    setShowDueDateReason(!showDueDateReason);
  };

  const formatTitle = (title) => {
    const match = title.match(/^\([^)]+\)\s*(.*)/);
    return match ? match[1] : title;
  };

  const toggleEmailExpansion = (index) => {
    setExpandedEmails(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const formatEmailContent = (content) => {
    const fromIndex = content.indexOf('From:');
    if (fromIndex === -1) return content;
    
    return (
      <>
        {content.substring(0, fromIndex)}
        <span className="email-sender">{content.substring(fromIndex)}</span>
      </>
    );
  };

  const EmailOrNoteItem = ({ item, index, isExpanded, toggleExpansion }) => {
    const isNote = item.type === 'note';
    const content = isNote ? item.note : item.contentText;
    const displayName = isNote ? item.employee?.person?.displayName : null;

    return (
      <li className={`email-note-item ${isNote ? 'note-item' : 'email-item'}`}>
        {isNote && displayName && (
          <div className="note-header">
            Note from {displayName}:
          </div>
        )}
        <div 
          className={`email-note-content ${isExpanded ? 'expanded' : ''}`}
          onClick={() => toggleExpansion(index)}
        >
          {isExpanded 
            ? (isNote ? content : formatEmailContent(content))
            : (isNote ? content.substring(0, 100) + (content.length > 100 ? '...' : '') : formatEmailContent(content.substring(0, 100) + '...'))}
          {content.length > 100 ? <FaChevronDown className="email-note-chevron" /> : null}
        </div>
      </li>
    );
  };

  if (isLoading) {
    return <div className="task-details-loading">Loading task details...</div>;
  }

  if (error) {
    return <div className="task-details-error">{error}</div>;
  }

  if (!task) {
    return <div className="task-details-not-found">Task not found.</div>;
  }

  const emailsAndNotes = [
    ...(task.posts || []).map(post => ({ ...post, type: 'email', createdAt: post.createdAt })),
    ...(task.notes || []).map(note => ({ ...note, type: 'note', createdAt: note.createdAt }))
  ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const handleDelete = async () => {
    try {
      await deleteTask();
      setShowConfirmation(false);
      if (isPopup && onClose) {
        onClose();
      }
    } catch (err) {
      console.error('Error deleting task:', err);
      setError('Failed to delete task. Please try again.');
    }
  };

  const handleDeleteClick = () => {
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleAddFile = () => {
    setShowAddFilesPopup(true);
  };

  const handleCloseAddFilesPopup = () => {
    setShowAddFilesPopup(false);
    fetchTaskDetails();
  };

  return (
    <div className={`task-details ${isPopup ? 'popup-mode' : ''}`}>
      {task && (
        <>
          {task.client && (
            <div className="client-name-container">
              {isAdmin ? (
                <div className="client-dropdown">
                  <h2 
                    className="client-name clickable" 
                    onClick={() => setShowClientDropdown(!showClientDropdown)}
                  >
                    {task.client.name} <FaChevronDown />
                  </h2>
                  {showClientDropdown && (
                    <ul className="client-list">
                      {clients.map((client) => (
                        <li 
                          key={client.id} 
                          onClick={() => handleClientChange(client.id)}
                        >
                          {client.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <h2 className="client-name">
                  {isPopup ? (
                    task.client.name
                  ) : (
                    <Link to={`/clients/${task.client.id}/reviews`}>{task.client.name}</Link>
                  )}
                </h2>
              )}
            </div>
          )}
          <h1>{formatTitle(task.title)}</h1>
          <p className="created-date">Created: {formatDate(task.createdAt)}</p>
          <h3 className="assigned-to">
            <strong>Assigned to:</strong> 
            <select 
              value={task.assignedTo?.id || ''} 
              onChange={handleAssignedToChange}
            >
              <option value="">Unassigned</option>
              {employees.map(emp => (
                <option key={emp.id} value={emp.id}>{emp.person.displayName}</option>
              ))}
            </select>
          </h3>
          
          <div className="task-info">
            <div className="section-header">
              <h3 className="section-heading">Details:</h3>
            </div>
            <div className="task-info-grid">
              <div className="task-info-item">
                <label htmlFor="state">State</label>
                <select 
                  id="state"
                  value={task.state} 
                  onChange={handleStateChange}
                >
                  {['OPEN', 'ASSIGNED', 'IN_PROGRESS', 'PENDING_CLIENT', 'INTERNAL_REVIEW', 'COMPLETED', 'CANCELLED'].map(state => (
                    <option key={state} value={state}>{state.replace(/_/g, ' ')}</option>
                  ))}
                </select>
              </div>
              
              <div className="task-info-item">
                <label htmlFor="priority">Priority</label>
                <select 
                  id="priority"
                  value={task.priority} 
                  onChange={handlePriorityChange}
                >
                  {['URGENT', 'HIGH', 'MEDIUM', 'LOW'].map(priority => (
                    <option key={priority} value={priority}>{priority}</option>
                  ))}
                </select>
              </div>
              
              <div className="due-completed-container">
                <div className="task-info-item">
                  <label htmlFor="dueDate">Due</label>
                  <div className="due-date-container">
                    <span className="date-picker-wrapper">
                      <DatePicker
                        id="dueDate"
                        selected={parseISO(task.dueDate)}
                        onChange={handleDueDateChange}
                        onCalendarClose={handleDueDateCalendarClose}
                        showTimeSelect
                        dateFormat="Pp"
                        customInput={<input type="text" />}
                      />
                    </span>
                    <button className="due-date-reason-toggle" onClick={toggleDueDateReason}>
                      <FaQuestionCircle />
                    </button>
                  </div>
                  {showDueDateReason && (
                    <div className="due-date-reason-overlay">
                      <p className="due-date-reason">{task.dueDateReason}</p>
                    </div>
                  )}
                </div>
                
                <div className="task-info-item">
                  <label htmlFor="completedDate">Completed</label>
                  <input 
                    id="completedDate"
                    type="text" 
                    value={task.state === 'COMPLETED' && task.completedDate ? formatDate(task.completedDate) : 'N/A'} 
                    readOnly 
                  />
                </div>
              </div>
              
              <div className="task-info-item">
                <label htmlFor="type">Material Type</label>
                <select 
                  id="type"
                  value={task.type} 
                  onChange={handleTypeChange}
                >
                  {['OTHER', 'PITCHBOOK', 'DDQ', 'FACT_SHEET', 'WEBSITE', 'INVESTOR_LETTER', 'PPM', 'SOCIAL_MEDIA', 'EMAIL', 'PERFORMANCE_DATA', 'WEBINAR'].map(type => (
                    <option key={type} value={type}>{type.replace(/_/g, ' ')}</option>
                  ))}
                </select>
              </div>
              
              <div className="task-info-item align-right">
                <label htmlFor="numberOfPages"># of Pages</label>
                <input 
                  id="numberOfPages"
                  type="number" 
                  value={task.numberOfPages} 
                  onChange={handleNumberOfPagesChange}
                  onBlur={handleNumberOfPagesBlur}
                  min="1"
                  max="9999"
                />
              </div>
              
              <div className="task-info-item">
                <label htmlFor="isAdvertisement">Review As Advertisement</label>
                <select 
                  id="isAdvertisement"
                  value={task.isAdvertisement ? 'Yes' : 'No'} 
                  onChange={handleIsAdvertisementChange}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              
              <div className="task-info-item">
                <label htmlFor="approved">IRP Approved</label>
                <select 
                  id="approved"
                  value={task.approved ? 'Yes' : 'No'} 
                  onChange={handleIsApprovedChange}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
          </div>

          <div className="task-attachments">
            <div className="section-header">
              <h3 className="section-heading">Files:</h3>
              <button className="add-file-button" onClick={handleAddFile}>
                <FaPaperclip /> Add File
              </button>
            </div>
            {task.files.length > 0 ? <FileTable files={task.files} /> : <p>No files available.</p>}
          </div>

          <div className="task-emails-notes">
            <div className="section-header">
              <h3 className="section-heading">Emails & Notes:</h3>
              <button className="add-note-button" onClick={handleAddNoteClick}>
                <FaPlus /> Add Note
              </button>
            </div>
            {emailsAndNotes.length > 0 ? (
              <ul>
                {emailsAndNotes.map((item, index) => (
                  <EmailOrNoteItem 
                    key={index}
                    item={item}
                    index={index}
                    isExpanded={expandedEmails[index]}
                    toggleExpansion={toggleEmailExpansion}
                  />
                ))}
              </ul>
            ) : (
              <p>No emails or notes available.</p>
            )}
          </div>

          {isAdmin && (
            <div className="delete-task-container">
              <button onClick={handleDeleteClick} className="delete-task-button">
                <FaTrash /> Delete Task
              </button>
            </div>
          )}

          {showConfirmation && (
            <div className="confirmation-dialog">
              <p>Are you sure you want to delete "{formatTitle(task.title)}"?</p>
              <button onClick={handleDelete}>Yes</button>
              <button onClick={handleCancelDelete}>No</button>
            </div>
          )}

          {showAddNotePopup && (
            <div className="add-note-popup">
              <div className="add-note-content">
                <textarea
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  placeholder="Enter your note here..."
                />
                <div className="add-note-actions">
                  <button className="post-note-button" onClick={handlePostNote}>Post</button>
                  <button className="cancel-note-button" onClick={handleCancelNote}>Cancel</button>
                </div>
              </div>
            </div>
          )}

          {showAddFilesPopup && (
            <Popup onClose={handleCloseAddFilesPopup} minHeight='200px'>
              <AddFiles
                accessToken={accessToken}
                apiBaseUrl={apiBaseUrl}
                handleSessionExpiration={handleSessionExpiration}
                taskId={task.id}
                onClose={handleCloseAddFilesPopup}
              />
            </Popup>
          )}
        </>
      )}
    </div>
  );
}

export default TaskDetails;